<template>
  <div class="fill-height" style="overflow:hidden">
    <div class="fill-height pa-0 ma-0 loginContext">
      <v-row class="fill-height pa-0 ma-0">
        <v-col cols="12" lg="4" class="white pa-0 ma-0 fill-height">
          <div class="formContext fill-height">
            <div class="formArea hiddenForAnimation">
              <div class="welcomeSection">
                بازنشانی رمز عبور
              </div>
              <p class="mt-1">
                رمز عبور جدید برای حساب کاربری خود مشخص کنید
              </p>
              <div style="width: 100%;height: 1px;background: #d1d0d4;opacity: 0.4"></div>
              <div class="mt-5">
                <v-form ref="resetPassword">
                  <div class="ltrForm">
                    <v-text-field :rules="rules.required" v-model="password" label="رمز عبور جدید : " dense outlined
                                  style=""></v-text-field>
                    <v-text-field :rules="rules.required" v-model="re_password" label="تکرار رمز عبور : " dense outlined
                                  style=""></v-text-field>
                  </div>
                </v-form>
                <v-btn color="primary" @click="resetPassword" block elevation="0" class="font-weight-bold mt-2">
                  ثبت
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="8" v-if="$vuetify.breakpoint.mdAndUp" class="vectorArea pa-0 ma-0 fill-height">
          <div class="bottomImage hiddenForAnimation">
            <img src="../../assets/images/bg-shape-image-light.png" alt="">
          </div>
          <div class="vectorImage hiddenForAnimation">
            <img src="../../assets/images/39.png" width="350" alt="">
          </div>
          <div class="logoArea hiddenForAnimation">
            <img src="../../assets/logo/logo-site-iran.png" alt="">
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      password: null,
      re_password: null,
    }
  },
  methods: {
    resetPassword() {
      if (this.$refs.resetPassword.validate()) {
        EventBus.$emit('setLoading', true)
        this.requesting('auth', 'rest-password', {}, {
          token: this.$route.params.token,
          password: this.password,
          password_confirmation: this.re_password,
        }).then(() => {
          EventBus.$emit('notify', {text: 'ویرایش رم عبور با موفقیت انحام شد', color: 'green'})
          this.$router.push({name: 'login'})
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$vuetify.breakpoint.mdAndDown) {
        gsap.fromTo('.formArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5})
      } else {
        gsap.fromTo('.bottomImage', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
          gsap.fromTo('.vectorImage', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
            gsap.fromTo('.logoArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
              gsap.fromTo('.formArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5})
            })
          })
        })
      }
    }, 500)
  }
}
</script>

<style>

@media (min-width: 200px) and (max-width: 800px) {
  .formArea {
    width: 80% !important;
  }
}

.vectorArea {
  background: #f8f7fa;
  position: relative;
}


.welcomeSection {
  font-size: 14pt;
  font-family: bold !important;
  color: #444050;
}

.formArea p {
  color: #444050;
}

.formArea .v-text-field--outlined fieldset {
  border-color: #d1d0d4;
}

.formContext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formArea {
  width: 60%;
  margin: 0px auto;
}

.bottomImage {
  position: absolute;
  bottom: -120px;
  right: 0px;
  left: 0px;
}

.bottomImage img {
  width: 100%;
}

.vectorImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoArea {
  position: absolute;
  top: 50px;
  right: 10px;
}

.logoArea img {
  width: 200px;
}
</style>
<template>
  <div class="">
    <v-card class="pa-5">
      <v-card-title class="font-weight-bold">
        فیلترها
      </v-card-title>
      <v-card-text>
        <div>
          <div class="heroTitle">
            تحلیل احساس کاربر:
          </div>
          <div class="d-flex mt-3">
            <div class="flex-grow-1 mx-2" v-for="(sentiment,i) in sentiments" :key="i">
              <div class="filterItem fill-height " :class="selected_sentiment == sentiment.key &&  'activeFilterItem'"
                   @click="selected_sentiment = sentiment.key">
                <div>
                  <img :src="require(`../../assets/emoji/${sentiment.key}.png`)" width="15px" class="mt-1" alt="">
                </div>
                <div class="fill-height d-flex justify-center flex-column mr-1 black--text">
                  {{ sentiment.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-10">
          <div class="heroTitle">
            بازه زمانی:
          </div>
          <div class="d-flex mt-3">
            <div class="flex-grow-1 mx-2" v-for="(time,i) in timePeriods" :key="i">
              <div class="filterItem fill-height " :class="selected_time == time.key &&  'activeFilterItem'"
                   @click="selected_time = time.key">
                <div class="flex-grow-1 text-center mr-1 black--text">
                  {{ time.title }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected_time == 'custom'" class="mt-8">
            <div class="d-flex flex-column">
              <v-form ref="custom">
                <date-picker clearable :column="1" v-model="from_data" class="mb-5" label="از تاریخ" mode="single"
                             :modal="true"></date-picker>
                <date-picker clearable :column="1" label="تا تاریخ" v-model="to_date" mode="single"
                             :modal="true"></date-picker>
              </v-form>
            </div>
          </div>
        </div>
        <div class="mt-10">
          <div class="heroTitle">
            زبان:
          </div>
          <div class="d-flex mt-3">
            <div class="filterItem flex-grow-1 ml-4" @click="selected_lang = 'fa'"
                 :class="selected_lang == 'fa' &&  'activeFilterItem'">
              FA -
              <!--                <img width="25px" height="15px" style="border-radius: 3px;filter: grayscale(1)" class="mt-1 ml-1"-->
              <!--                     src="../../../assets/icons/iran.png" alt="">-->
              فارسی
            </div>
            <div class="filterItem flex-grow-1 ml-4" @click="selected_lang = 'ar'"
                 :class="selected_lang == 'ar' &&  'activeFilterItem'">
              AR -
              <!--                <img width="25px" height="15px" style="border-radius: 3px;filter: grayscale(1)" class="mt-1 ml-1"-->
              <!--                     src="../../../assets/icons/iran.png" alt="">-->
              عربی
            </div>
            <div class="filterItem flex-grow-1" @click="selected_lang = 'exclude_fa'"
                 :class="selected_lang == 'exclude_fa' &&  'activeFilterItem'">
              <v-icon>mdi-earth</v-icon>
              زبان‌های دیگر
            </div>
          </div>
        </div>
        <div class="mt-10">
          <v-btn color="primary" rounded elevation="0" small @click="applyFilters">
            اعمال فیلتر
          </v-btn>
          <v-btn color="secondary" rounded class="mr-4" elevation="0" small @click="resetFilter">
            حذف فیلتر
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment/moment";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      sentiments: [
        {
          title: 'منفی',
          key: 'negative',
        },
        {
          title: 'طبیعی',
          key: 'neutral',
        },
        {
          title: 'مثبت',
          key: 'positive',
        }
      ],
      timePeriods: [
        {
          title: 'امروز',
          key: 'today'
        },
        {
          title: 'یک هفته اخیر',
          key: 'week'
        },
        {
          title: '1 ماه اخیر',
          key: 'month'
        },
        {
          title: 'تاریخ دلخواه',
          key: 'custom'
        },
      ],
      selected_sentiment: null,
      selected_time: null,
      from_data: null,
      to_date: null,
      selected_lang: null,
    }
  },
  methods: {
    applyFilters() {
      let payload = {
        sentiment: this.selected_sentiment,
        selected_time: this.selected_time,
        selected_lang: this.selected_lang,
      }
      this.prepareTimePeriods(payload)
      if (this.selected_lang) {
        payload.lang = this.selected_lang
      }
      if (this.selected_time === 'custom') {
        if (!this.from_data || !this.to_date) {
          EventBus.$emit('notify', {text: 'هر دو تاریخ را وارد کنید', color: 'red'})
          return;
        }
      }
      this.$emit('applyFilter', payload)
    },
    prepareTimePeriods(filters) {
      switch (this.selected_time) {
        case "today":
          filters.from_date = moment().format('YYYY-MM-DD')
          filters.to_date = moment().format('YYYY-MM-DD')
          break;
        case "yesterday":
          filters.from_date = moment().subtract('1', 'days').format('YYYY-MM-DD')
          filters.to_date = moment().subtract('1', 'days').format('YYYY-MM-DD')
          break;
        case "week":
          filters.from_date = moment().subtract('7', 'days').format('YYYY-MM-DD')
          filters.to_date = moment().format('YYYY-MM-DD')
          break;
        case "month":
          filters.from_date = moment().subtract('1', 'month').format('YYYY-MM-DD')
          filters.to_date = moment().format('YYYY-MM-DD')
          break;
        case "custom":
          filters.from_date = this.from_data
          filters.to_date = this.to_date
      }
    },
    resetFilter() {
      this.selected_sentiment = null
      this.selected_time = null
      this.selected_lang = null
      this.$emit('resetFilter')
    }
  },

}
</script>

<style lang="scss" scoped>
.filterItem {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #dddddd;
  display: flex;
  transition: all 0.3s;
  cursor: pointer;
}

.activeFilterItem {
  border-color: #ff8601;
  color: black !important;
  transition: all 0.3s;
  font-weight: bolder;
}

</style>
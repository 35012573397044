import auth from './list/auth'
import dashboard from './list/dashboardRequest'
import keyword from './list/keywordRequest'
import ticket from './list/TicketRequest'
import user from './list/usersRequests'
import report from './list/reportRequest'

export default {
    auth,
    dashboard,
    keyword,
    ticket,
    user,
    report
}
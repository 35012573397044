const baseUrl = 'user/'
/*eslint-disable*/
export default [
    {
        name: "getDashboard",
        path: baseUrl + "dashboard/overview",
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
]
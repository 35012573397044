import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes";
// import store from '../store';
/*eslint-disable*/
Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.role === 'guest') {
        if (localStorage.getItem('token') == null) {
            next();
        } else {
            next('/dashboard');
        }
    } else if (to.meta.role === 'admin') {
        if (localStorage.getItem('token') != null) {
            next()
        } else {
            next('/login')
        }
    } else {
        next();
    }
})
export default router

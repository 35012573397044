<template>
  <div class="fill-height" style="overflow:hidden">
    <div class="fill-height pa-0 ma-0 loginContext">
      <v-row class="fill-height pa-0 ma-0">
        <v-col cols="12" lg="4" class="white pa-0 ma-0 fill-height">
          <div class="formContext fill-height">
            <div class="formArea hiddenForAnimation">
              <div class="text-center">
                <img src="../../assets/logo/logo-site-iran.png" width="30%" alt="">
                <div class="welcomeSection mt-3">
                  به آژانس سوشال لیسنینگ ایران خوش آمدید
                  👋
                </div>
                <p class="mt-1">
                  قبل از صحبت کردن گوش کنید
                </p>
              </div>
              <div style="width: 100%;height: 1px;background: #d1d0d4;opacity: 0.4"></div>
              <div class="mt-5">
                <v-fade-transition mode="out-in">
                  <v-form ref="forget" :key="1" @submit.prevent v-if="state == 'get-email'"
                          @keypress.native.enter="forgetPassword">
                    <div class="ltrForm">
                      <v-text-field v-model="email" :rules="rules.emailRules" label="ایمیل : " dense outlined
                                    style=""></v-text-field>
                    </div>
                  </v-form>
                  <div :key="2" v-else>
                    <v-alert type="success">
                      ایمیل حاوی لینک بازیابی رمز عبور برای شما ارسال شد.
                    </v-alert>
                  </div>
                </v-fade-transition>
                <v-btn
                    @click="forgetPassword" v-if="state == 'get-email'" color="primary" block elevation="0"
                    class="font-weight-bold mt-2">
                  بازیابی رمز عبور
                </v-btn>

                <div class="d-flex justify-center mt-3">
                  <div class="mt-4" style="font-size: 13px">
                    حساب کاربری دارید؟
                    <router-link to="/login">
                    <span class="primary--text font-weight-bold mr-2">
                       وارد شوید
                    </span>
                    </router-link>
                  </div>
                </div>
                <!--                <div class="d-flex justify-center mt-3">-->
                <!--                  <div class="mt-4" style="font-size: 13px">-->
                <!--                    حساب کاربری ندارید؟-->
                <!--                    <span class="primary&#45;&#45;text font-weight-bold mr-2">-->
                <!--                       ثبت نام-->
                <!--                    </span>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="8" v-if="$vuetify.breakpoint.mdAndUp" class="vectorArea pa-0 ma-0 fill-height">
          <div class="bottomImage hiddenForAnimation">
            <img src="../../assets/images/bg-shape-image-light.png" alt="">
          </div>
          <div class="vectorImage hiddenForAnimation">
            <img src="../../assets/images/39.png" width="350" alt="">
          </div>
          <div class="logoArea hiddenForAnimation">
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      state: 'get-email',
      email: ''
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$vuetify.breakpoint.mdAndDown) {
        gsap.fromTo('.formArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5})
      } else {
        gsap.fromTo('.bottomImage', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
          gsap.fromTo('.vectorImage', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
            gsap.fromTo('.logoArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
              gsap.fromTo('.formArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5})
            })
          })
        })
      }
    }, 500)
  },
  methods: {
    forgetPassword() {
      if (this.$refs.forget.validate()) {
        EventBus.$emit('setLoading', true)
        this.requesting('auth', 'forget-password', {}, {
          email: this.email,
        }).then(() => {
          this.state = 'done'
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  }
}
</script>

<style>

@media (min-width: 200px) and (max-width: 800px) {
  .formArea {
    width: 80% !important;
  }
}

.vectorArea {
  background: #f8f7fa;
  position: relative;
}


.welcomeSection {
  font-size: 14pt;
  font-family: bold !important;
  color: #444050;
}

.formArea p {
  color: #444050;
}

.formArea .v-text-field--outlined fieldset {
  border-color: #d1d0d4;
}

.formContext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formArea {
  width: 60%;
  margin: 0px auto;
}

.bottomImage {
  position: absolute;
  bottom: -120px;
  right: 0px;
  left: 0px;
}

.bottomImage img {
  width: 100%;
}

.vectorImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoArea {
  position: absolute;
  top: 50px;
  right: 10px;
}

.logoArea img {
  width: 200px;
}
</style>
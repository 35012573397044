/*eslint-disable*/

import MainLayout from "@/layouts/mainLayout";
import DashboardLayout from "@/layouts/dashboardLayout";
import Login from "@/views/auth/login";
import Register from "@/views/auth/register";
import ForgetPassword from "@/views/auth/forgetPassword";
import ResetPassword from "@/views/auth/resetPassword";
import Dashboard from "@/views/dashboard/dashboard";
import Keywords from "@/views/dashboard/keyword/keywords";
import TicketList from "@/views/dashboard/support/TicketList";
import TicketDetail from "@/views/dashboard/support/TicketDetail";
import KeywordDetail from "@/views/dashboard/keyword/KeywordDetail";
import Profile from "@/views/dashboard/User/Profile";
import NotificationPage from "@/views/dashboard/Notification/NotificationPage.vue";
import SubscriptionManager from "@/views/dashboard/Subscriptions/SubscriptionManager.vue";
import Feedback from "@/views/dashboard/Feedback/Feedback.vue";
import DeckDashboard from "../views/dashboard/Deck/DeckDashboard";
import ReportList from "@/views/dashboard/Report/ReportList.vue";
import AddReport from "@/views/dashboard/Report/AddReport.vue";
import Importkeywords from "@/views/dashboard/keyword/Importkeywords.vue";
import InAppNotification from "@/views/dashboard/InAppNotification/InAppNotification.vue";

const routes = [
    {
        path: "/",
        component: MainLayout,
        redirect: 'dashboard',
        children: [
            {
                path: "dashboard",
                component: DashboardLayout,
                meta: {
                    // role: "admin"
                },
                children: [
                    {
                        path: "",
                        component: Dashboard,
                        name: "index",
                        meta: {
                            title: 'داشبورد'
                        }
                    },
                    {
                        path: "profile",
                        component: Profile,
                        name: "index",
                        meta: {
                            title: 'داشبورد'
                        }
                    },
                    {
                        path: "deck",
                        component: DeckDashboard,
                        name: "deck",
                        meta: {
                            title: 'پیشخوان دیتا'
                        }
                    },
                    {
                        path: "reports",
                        component: ReportList,
                        name: "report",
                        meta: {
                            title: 'گزارشات'
                        }
                    },
                    {
                        path: "reports/add",
                        component: AddReport,
                        name: "AddReport",
                        meta: {
                            title: 'ساخت گزارش'
                        }
                    },
                    {
                        path: "keywords",
                        component: Keywords,
                        name: "keyword",
                        meta: {
                            title: 'کلید‌واژه‌ها'
                        }
                    },
                    {
                        path: "import-keywords",
                        component: Importkeywords,
                        name: "ImportKeyword",
                        meta: {
                            title: 'وارد کردن کلیدواژه با اکسل'
                        }
                    },
                    {
                        path: "keywords/:id",
                        component: KeywordDetail,
                        name: "keywordDetail",
                        meta: {
                            title: 'کلید‌واژه‌'
                        }
                    },
                    {
                        path: "support",
                        component: TicketList,
                        name: "support",
                        meta: {
                            title: 'تیکت‌ها'
                        }
                    },
                    {
                        path: "support/:id",
                        component: TicketDetail,
                        name: "ticket",
                        meta: {
                            title: 'تیکت'
                        }
                    },
                    {
                        path: "notification",
                        component: NotificationPage,
                        name: "notifications",
                        meta: {
                            title: 'مدیریت اعلان ها'
                        }
                    },
                    {
                        path: "subscriptions",
                        component: SubscriptionManager,
                        name: "subscriptions",
                        meta: {
                            title: 'مدیریت اشتراک'
                        }
                    },
                    {
                        path: "feedback",
                        component: Feedback,
                        name: "feedback",
                        meta: {
                            title: 'ثبت بازخورد و نظرات'
                        }
                    },
                    {
                        path: "in-app-notification",
                        component: InAppNotification,
                        name: "in-app-notification",
                        meta: {
                            title: 'اعلانات'
                        }
                    },
                ],
            },
            {
                path: "login",
                component: Login,
                name: "login",
                meta: {
                    role: 'guest',
                }
            },
            // {
            //     path: "register",
            //     component: Register,
            //     name: 'register',
            //     meta: {
            //         role: 'guest',
            //     }
            // },
            {
                path: "forget-password",
                component: ForgetPassword,
                name: 'register',
                meta: {
                    role: 'guest',
                }
            },
            {
                path: "reset-password/:token",
                component: ResetPassword,
                name: 'reset_password',
                meta: {
                    role: 'guest',
                }
            },
        ]

    },
]

export default routes;

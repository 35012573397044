<template>
  <div>
    <div class="borderBottomBox hiddenForAnimation  keywordsTable">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          کلیدواژه‌ها
        </div>
        <div>
          <v-btn  :disabled="!canAddKeyword" color="secondary" elevation="0" class="font-weight-bold "
                 style="border-radius: 10px"
                  :to="{name:'ImportKeyword'}">
            <v-icon small class="ml-2">mdi-file</v-icon>
            وارد کردن فایل اکسل
          </v-btn>
          <v-btn :disabled="!canAddKeyword" color="secondary" elevation="0" class="font-weight-bold mr-2"
                 style="border-radius: 10px"
                 @click="addBlankKeyword">
            <v-icon>mdi-plus</v-icon>
            افزودن کلید‌واژه
          </v-btn>
        </div>
      </div>
      <v-alert type=" mt-5 mb-5"
               v-if="subscription_error"
               style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
        <b>
          کاربر گرامی؛
        </b>
        <template v-if="subscription_error == 'no_subscription'">
          <br>
          اشتراکی روی حساب کاربری شما فعال نیست. لطفا از بخش پشتیبانی اقدام به فعال سازی
          <b>اشتراک</b>
          کنید
        </template>
        <template v-else-if="subscription_error == 'subscription_limit'">
          <br>
          شما به
          <b>
            محدویت تعداد کلمات کلیدی اشتراک
          </b>
          خود رسیده اید. می توانید در صورت نیاز از بخش پشتیبانی اقدام به ارتقای
          اشتراک خود کنید.

        </template>
      </v-alert>
      <div class="mt-6">
        <div class="keywordItem" v-for="(keyword, i) in newKeyword" :key="i">
          <KeywordItem @afterAdd="afterAdd" :keyword="keyword"/>
        </div>
      </div>
      <div class="mt-6">
        <div class="keywordItem" v-for="(keyword, i) in data" :key="i">
          <KeywordItem @afterDelete="afterDelete" @afterEdit="afterEdit" :keyword="keyword"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/*eslint-disable*/

import {gsap} from "gsap";
import KeywordItem from "@/components/Keyword/KeywordItem";
import {EventBus} from "@/event-bus";
import DeleteModal from "@/components/Modal/DeleteModal";

export default {
  components: {DeleteModal, KeywordItem},
  data() {
    return {
      singleExpand: false,
      data: [],
      newKeyword: [],
      subscription_error: null,
    }
  },
  computed: {
    canAddKeyword() {
      this.subscription_error = null
      if (!this.subscription) {
        this.subscription_error = 'no_subscription'
        return false
      }
      let subscription_details = this.subscription.subscription
      if (!subscription_details.keyword_limit_count) {
        return true
      } else {
        if (this.data) {
          if (this.data.length >= subscription_details.keyword_limit_count) {
            this.subscription_error = 'subscription_limit'
            return false
          } else {
            return true
          }
        }
      }
      return false
    },
  },
  methods: {

    afterAdd() {
      this.newKeyword = []
      this.getKeywords(false)
    },
    afterEdit() {
      this.getKeywords(false)
    },
    afterDelete() {
      this.getKeywords(false)
    },
    addBlankKeyword() {
      this.newKeyword.unshift(
          {
            "social_media": {},
            "status": false,
            "pattern": "",
            "search_post": false,
            "search_commnet": false,
            "data_count": 0
          },
      )
    },
    getKeywords(need_to_load = true) {
      EventBus.$emit('setLoading', true)
      this.requesting('keyword', 'getKeywords').then((resp) => {
        this.data = null
        this.$nextTick().then(() => {
          this.data = resp.data.keywords
        })
        if (need_to_load) {
          this.loadKeyword()
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    loadKeyword() {
      setTimeout(() => {
        gsap.fromTo('.keywordsTable', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
        }).then(() => {
        })
      }, 100)
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.getKeywords()
    })
  }

}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 800px) {
  .countData {
    margin-top: -10px !important;
    margin-right: 0px !important;
  }
}

.keywordItem {
  border: 1px solid #dddddd;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.inputBox {
  color: rgba(0, 0, 0, 0.6) !important;
  margin: 0px 15px;
}

.countData {
  font-size: 12px;
}

</style>
<template>
  <div>
    <div class="my-3 d-flex justify-space-between">
      <div class="heroTitle">
        داشبورد
      </div>
      <v-btn class="tableBtn" elevation="0" small to="/dashboard/deck">
        پیشخوان دیتا
      </v-btn>
    </div>
    <v-container v-if="dashboardData">
      <v-row class="">
        <v-col cols="12" lg="3">
          <div class="borderBottomBox mainBoxShadow boxStatistics hiddenForAnimation ">
            <div class="d-flex">
              <div class="iconAreaBox">
                <v-icon color="primary">mdi-keyboard</v-icon>
              </div>
              <div class="d-flex flex-column justify-center">
                <div class="countStatistics">
                  {{ dashboardData.overview.keyword_count }}
                </div>
              </div>
            </div>
            <div class="titleBox">
              کلیدواژه‌های فعال
            </div>

          </div>
        </v-col>
        <v-col cols="12" lg="3">
          <div class="borderBottomBox mainBoxShadow boxStatistics hiddenForAnimation ">
            <div class="d-flex">
              <div class="iconAreaBox">
                <v-icon color="primary">mdi-database-clock</v-icon>
              </div>
              <div class="d-flex flex-column justify-center">
                <div class="countStatistics">
                  {{ dashboardData.overview.today_data }}
                </div>
              </div>
            </div>
            <div class="titleBox">
              دیتا‌های دریافتی امروز
            </div>

          </div>
        </v-col>
        <v-col cols="12" lg="3">
          <div class="borderBottomBox mainBoxShadow boxStatistics hiddenForAnimation ">
            <div class="d-flex">
              <div class="iconAreaBox">
                <v-icon color="primary">mdi-database</v-icon>
              </div>
              <div class="d-flex flex-column justify-center">
                <div class="countStatistics">
                  {{ dashboardData.overview.total_data }}
                </div>
              </div>
            </div>
            <div class="titleBox">
              مجموع دیتا‌های دریافتی
            </div>

          </div>
        </v-col>
        <v-col cols="12" lg="3">
          <div class="borderBottomBox mainBoxShadow boxStatistics hiddenForAnimation ">
            <div class="d-flex">
              <div class="iconAreaBox">
                <v-icon color="primary">mdi-face-agent</v-icon>
              </div>
              <div class="d-flex flex-column justify-center">
                <div class="countStatistics">
                  {{ dashboardData.overview.ticket_count }}
                </div>
              </div>
            </div>
            <div class="titleBox">
              تیکت‌ها
            </div>

          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <div class="borderBottomBox hiddenForAnimation keywordsTable mt-10" style="min-height: 400px">
            <div class="heroTitle">
              تحلیل احساسات کاربران
            </div>
            <div class="mt-5">
              <SentimentChart :sentiment_chart="dashboardData.sentiment_chart"/>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <div class="borderBottomBox hiddenForAnimation keywordsTable mt-10" style="min-height: 400px">
            <div class="heroTitle">
              نمودار روند اتتشار محتوا
            </div>
            <div class="mt-5">
              <DashboardDataChart :data_chart="dashboardData.data_chart"/>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="borderBottomBox hiddenForAnimation keywordsTable mt-10">
        <div class="heroTitle">
          آمار کلیدواژه‌ها
        </div>
        <div class="mt-4">
          <v-data-table :items="dashboardData.keywords.data" :headers="headers" disable-pagination hide-default-footer>
            <template v-slot:item.action="{item}">
              <v-btn small :to="`/dashboard/keywords/${item.id}`" elevation="0" class="ma-5 tableBtn">
                مشاهده
                <v-icon small class="mr-1">mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.state="{item}">
              <span class="mr-2" :class="`${item.state}_state`">
                  {{ item.state | stateFilter }}
                </span>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="borderBottomBox hiddenForAnimation keywordsTable mt-10">
        <div class="heroTitle">
          آخرین تیکت‌های شما
        </div>
        <div class="mt-4">
          <v-data-table :items="dashboardData.tickets" :headers="ticket_headers" disable-pagination hide-default-footer>
            <template class="" v-slot:item.action="{item}">
              <v-btn :to="`/dashboard/support/${item.id}`" small elevation="0" class="tableBtn ma-5">
                مشاهده
                <v-icon small class="mr-1">mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.state="{item}">
              <template v-if="item.state == 'wait_for_user'">
                <div class="answerTickets">
                  پاسخ داده‌شده
                </div>
              </template>
              <template v-else-if="item.state == 'wait_for_admin'">
                <div class="NotAnswerTickets">
                  در انتظار پاسخ
                </div>
              </template>
              <template v-else-if="item.state == 'close'">
                <div class="closeTickets">
                  بسته‌شده
                </div>
              </template>
            </template>

            <template v-slot:item.last_update_at="{item}">
              {{ item.last_update_at | filteringTimeWithTime }}
            </template>
          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";
import moment from "moment-jalaali";
import SentimentChart from "@/components/Chart/SentimentChart.vue";
import DataChart from "@/components/Chart/DataChart.vue";
import DashboardDataChart from "@/components/Chart/DashboardDataChart.vue";

export default {
  components: {DashboardDataChart, DataChart, SentimentChart},
  data() {
    return {
      data: [],
      headers: [
        {
          text: 'کد',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'کلید‌واژه',
          align: 'center',
          sortable: false,
          value: 'pattern',
        },
        {
          text: 'شبکه‌اجتماعی',
          align: 'center',
          sortable: false,
          value: 'social_media.name',
        },
        {
          text: 'تعداد دیتا‌ دریافتی',
          align: 'center',
          sortable: false,
          value: 'data_count',
        },
        {
          text: 'وضعیت',
          align: 'center',
          sortable: false,
          value: 'state',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      ticket_headers: [
        {
          text: 'کد تیکت',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'عنوان تیکت',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: 'وضعیت',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'آخرین پیام',
          align: 'start',
          sortable: false,
          value: 'last_update_at',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      dashboardData: null,
      sentiment_series: [],
      sentiment_series_label: [],
      data_series_label: [],
      data_series: [],

    }
  },
  methods: {

    getDashboardData() {
      EventBus.$emit('setLoading', true)
      this.requesting('dashboard', 'getDashboard').then((resp) => {
        this.dashboardData = resp.data
        console.log(this.dashboardData, '1212121')
        // this.prepareDataChartData()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
        this.loadDashboard()
      })
    },
    loadDashboard() {
      setTimeout(() => {
        gsap.fromTo('.boxStatistics', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
          stagger: 0.3,
        }).then(() => {
          gsap.fromTo('.keywordsTable', {y: 100, opacity: 0}, {y: 0, opacity: 1})
        })
      }, 100)

    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.getDashboardData()
    })
  },

}
</script>

<style scoped>

</style>
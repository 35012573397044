<template>
  <div>
    <div class="borderBottomBox hiddenForAnimation  keywordsTable">
      <v-row>
        <v-col cols="12" lg="4">
          <v-form ref="import">
            <v-file-input v-model="file" label="فایل" :rules="rules.required" outlined dense
                          hint="فقط فرمت های xlsx و csv پشتبیانی میشنود" persistent-hint/>
            <v-btn color="primary" elevation="0" @click="importExcelFile" small> آپلود</v-btn>
          </v-form>
        </v-col>
      </v-row>
      <v-alert type=" mt-5" style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
        <b>
          کاربر گرامی؛
        </b>
        <br>
        به نکات زیر برای ساخت فایل اکسل دقت کنید:
        <ul class="mr-10 mt-3">
          <li>
            هدر های فایل اکسل را مانند نمونه قرار دهید و تغیر در آن‌ها ایجاد نکنید
          </li>
          <li>
            فیلد Platform باید یکی از شبکه‌های اجتماعی از بین
            <v-chip small color="grey mx-1">
              Twitter
            </v-chip>
            <v-chip small color="grey mx-1">
              Linkedin
            </v-chip>
            <v-chip small color="grey mx-1">
              Quora
            </v-chip>
            <v-chip small color="grey mx-1">
              HackerNews
            </v-chip>
            <v-chip small color="grey mx-1">
              Reddit
            </v-chip>
            <v-chip small color="grey mx-1">
              Telegram
            </v-chip>
            <v-chip small color="grey mx-1">
              Instagram
            </v-chip>
            <v-chip small color="grey mx-1">
              Facebook
            </v-chip>
            باشد.
            <b>
              در تایپ آن‌ها دقت کنید.
            </b>
          </li>
          <li>
            در فیلدهای
            Post
            و
            Comment
            مقادیر Yes و No را قرار دهید.
          </li>
          <li>
            فیلدهای
            Exclude_keyword
            و
            Exclude_user
            و
            Include_user
            را میتوانید خالی بگذارید.
          </li>
        </ul>
        <a href="https://backend.sociallistening.ir/sample_excel.xlsx" target="_blank">
          <v-btn color="primary" elevation="0" small>
            دانلود فایل نمونه
          </v-btn>
        </a>
      </v-alert>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/

import {gsap} from "gsap";
import KeywordItem from "@/components/Keyword/KeywordItem";
import {EventBus} from "@/event-bus";
import DeleteModal from "@/components/Modal/DeleteModal";

export default {
  components: {DeleteModal, KeywordItem},
  data() {
    return {
      file: null,
    }
  },
  computed: {},
  methods: {
    importExcelFile() {
      if (this.$refs.import.validate()) {
        let payload = new FormData();
        payload.append('file', this.file)
        EventBus.$emit('setLoading', true)
        this.requesting('keyword', 'importExcelFile', {}, payload).then((resp) => {
          EventBus.$emit('notify', {text: 'کلمات کلیدی شما اضافه شدند', color: 'green'})
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
    loadKeyword() {
      setTimeout(() => {
        gsap.fromTo('.keywordsTable', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
        }).then(() => {
        })
      }, 100)
    }
  },
  mounted() {
    this.loadKeyword()
  }

}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 800px) {
  .countData {
    margin-top: -10px !important;
    margin-right: 0px !important;
  }
}

.keywordItem {
  border: 1px solid #dddddd;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.inputBox {
  color: rgba(0, 0, 0, 0.6) !important;
  margin: 0px 15px;
}

.countData {
  font-size: 12px;
}

li {
  margin-bottom: 10px;
}

</style>
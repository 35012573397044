const baseUrl = 'user/'
/*eslint-disable*/
export default [
    {
        name: "getTickets",
        path: baseUrl + "support/tickets/all",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "makeTicket",
        path: baseUrl + "support/tickets/send-ticket",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getTicket",
        path: baseUrl + "support/tickets/:id/get",
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "sendMessage",
        path: baseUrl + "support/tickets/:id/send-message",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
]
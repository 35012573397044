<template>
  <div>
    <div class="borderBottomBox hiddenForAnimation  notification">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          مدیریت اشتراک ها
        </div>
      </div>
      <v-alert type=" mt-5" style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
        <b>
          کاربر گرامی؛
        </b>
        <br>
        برای تمدید یا فعال کردن اشتراک از طریق بخش
        <b>
          پشتیبانی
        </b>
        اقدام به ارسال تیکت کنید.
      </v-alert>

      <v-data-table class="mt-5" :headers="history_headers" :items="subscriptions_history"
                    disable-pagination
                    hide-default-footer>
        <template v-slot:item.created_at="{item}">
          {{ item.created_at | dateFilterWithOutTime }}
        </template>
        <template v-slot:item.expired_at="{item}">
          {{ item.expired_at | dateFilterWithOutTime }}
        </template>
        <template v-slot:item.state="{item}">
          <div :class="`subscription_${item.state}_state`">
            {{ item.state | SubscriptionStateFilter }}
          </div>
        </template>
        <template v-slot:item.action="{item}">
          <v-btn to="/dashboard/support" v-if="item.state == 'valid'" small elevation="0" class="tableBtn">
            ارتقای اشتراک
            <v-icon small class="mr-1">mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn to="/dashboard/support" v-else-if="item.state == 'expired'" small elevation="0" class="tableBtn">
            تمدید اشتراک
            <v-icon small class="mr-1">mdi-arrow-left</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      subscriptions_history: [],
      history_headers: [
        {text: 'نام اشتراک', value: 'subscription.name'},
        {text: 'شروع اعتبار', value: 'created_at'},
        {text: 'پایان اعتبار', value: 'expired_at'},
        {text: 'وضعیت', value: 'state', align: "start"},
        {text: '', value: 'action', align: "start"},
      ]
    }
  },

  methods: {
    load() {
      setTimeout(() => {
        gsap.fromTo('.notification', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
        }).then(() => {
        })
      }, 500)
    },
    getUserSubscriptions(load = true) {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'getUserSubscriptionHistory').then((resp) => {
        this.subscriptions_history = resp.data.subscriptions
        if (load) {
          this.load()
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getUserSubscriptions()
  }
}
</script>

<style lang="scss" scoped>

</style>
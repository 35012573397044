<template>
  <div>
    <div class="borderBottomBox hiddenForAnimation  notification">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          ثبت بازخورد ها
        </div>
      </div>
      <v-alert type=" mt-5" style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
        <b>
          کاربر گرامی؛
        </b>
        <br>
        بازخوردهای شما ما را برای ساخت محصولی بهتر یاری می کند.
      </v-alert>
      <v-form ref="feedback" class="mt-10">
        <v-row>
          <v-col cols="12" lg="6">
            <v-textarea v-model="feedback" :rules="rules.required" outlined label="متن بازخورد"></v-textarea>
            <v-btn color="primary" elevation="0" @click="submitFeedback">
              ثبت بازخورد
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      feedback: null,
    }
  },

  methods: {
    submitFeedback() {
      if (this.$refs.feedback.validate()) {
        EventBus.$emit('setLoading',true)
        this.requesting('user', 'submitFeedback', {}, {
          message: this.feedback
        }).then((resp) => {
          EventBus.$emit('notify', {text: 'بازخورد شما ثبت شد.', color: 'green'})
          setTimeout(() => {
            this.$router.push('/dashboard')
          }, 500)
        }).finally(() => {
          EventBus.$emit('setLoading',false)
        })
      }
    },
    load() {
      setTimeout(() => {
        gsap.fromTo('.notification', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
        }).then(() => {
        })
      }, 500)
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>

</style>
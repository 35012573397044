<template>
  <div>
    <apexchart type="line"
               :options="data_chartOptions" :series="data_series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment-jalaali";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data_chart: Array
  },
  computed: {
    data_chartOptions() {
      return {
        chart: {
          id: 'line-chart',
          toolbar: {
            show: false // Hide the toolbar including icons
          }
        },
        title: {
          text: '',
          align: 'center'
        },
        stroke: {
          curve: 'smooth' // Make the line smooth
        },
        markers: {
          size: 6, // Size of the markers
          strokeColors: '#ff8601', // Border color of the markers
          strokeWidth: 1, // Border width of the markers
          borderRadius: 50 // Border radius of the markers (make them circular)
        },
        xaxis: {
          categories: this.data_series_label // Example categories
        },
        yaxis: {
          min: 0
        },
        // Line color
      }
    }
  },
  data() {
    return {
      data_series_label: [],
      data_series: [],
    }
  },
  methods: {
    prepareDataChartData() {
      this.data_series = [
        {
          name: 'دیتای دریافتی',
          data: []
        }
      ]
      this.data_chart.forEach((item) => {
        this.data_series_label.push(moment(item.date).format('jMM/jDD'))
      })
      this.data_chart.forEach((item) => {
        this.data_series[0].data.push(item.total)
      })
    },
  },
  mounted() {
    this.prepareDataChartData()
  },
  watch: {
    data_chart() {
      this.prepareDataChartData()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"borderBottomBox"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"heroTitle"},[_vm._v(" گزارشات ")]),_c('div',[_c('v-btn',{staticClass:"font-weight-bold",staticStyle:{"border-radius":"10px"},attrs:{"color":"secondary","small":"","elevation":"0","to":"/dashboard/reports/add"}},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-plus")]),_vm._v(" ایجاد ")],1)],1)]),_c('div',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filteringTime")(item.created_at))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type =='excel')?[_vm._v(" اکسل ")]:(item.type == 'pdf')?[_vm._v(" فایل ")]:(item.type == 'csv')?[_vm._v(" csv ")]:_vm._e()]}},{key:"item.file_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"direction":"ltr !important","text-align":"right"}},[_vm._v(" "+_vm._s(item.file_name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.file_url,"target":"_blank"}},[_c('v-btn',{staticClass:"ma-5 tableBtn",attrs:{"elevation":"0","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")]),_vm._v(" دانلود ")],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
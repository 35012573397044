<template>
  <div>
    <v-dialog width="550px" v-model="addModal" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title style="font-family: bold !important;">
          افزودن پیام
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="ticket">
            <v-textarea v-model="newMessage.message" :rules="rules.required" outlined label="متن پیام"></v-textarea>
            <!--            <v-file-input v-model="newMessage.file" label="فایل" outlined dense persistent-hint-->
            <!--                          hint="این فایل ها به صورت امن نگه داری می‌شوند"></v-file-input>-->
          </v-form>
          <v-btn @click="sendMessge" color="primary" elevation="0" class="mt-3">
            ارسال
            <v-icon style="transform: rotate(180deg)" class="mr-2">mdi-send</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-fade-transition>
      <div class="borderBottomBox" v-if="ticket">
        <div class="d-flex justify-space-between">
          <div class="heroTitle">
            تیکت#
            {{ ticket.id }}
            -
            {{ ticket.title }}
          </div>
          <div>
            <v-btn color="secondary" elevation="0" class="font-weight-bold" style="border-radius: 10px"
                   @click="addModal = true">
              <v-icon class="ml-1">mdi-pencil</v-icon>
              افزودن پیام
            </v-btn>
          </div>

        </div>
        <div class="messagesArea mt-10">
          <div class="d-flex mb-6" v-for="(message , i) in ticket.messages" :key="i"
               :class="ticket.user.id == message.sender_id ? 'justify-start' : 'justify-end'"
          >
            <div class="">
              <div class="message" :class="ticket.user.id == message.sender_id ? 'userMessage' : 'adminMessage'">
                {{ message.message }}
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  <div class="dateTicket">
                    {{ message.created_at | dateFilterV2 }}
                  </div>
                </div>
                <div v-if="message.file">
                  <a :href="message.file.url" target="_blank">
                    <v-btn x-small color="primary" elevation="0" style="margin-top: -12px">
                      دانلود فایل
                    </v-btn>
                  </a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </v-fade-transition>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      messages: [
        {
          sender: 'user',
          message: "سلام این سایت شما مشکل فنی داره ما با مشکل های زیادی داریم کار میکنیم.",
          created_at: '1400/02/03'
        },
        {
          sender: 'admin',
          message: "داریم حلش میکنیم",
          created_at: '1400/02/03'
        },
      ],
      ticket: null,
      addModal: false,
      newMessage: {
        message: '',
        file: '',
      }
    }
  },
  methods: {
    getTicket() {
      EventBus.$emit('setLoading', true)
      this.requesting('ticket', 'getTicket', {id: this.$route.params.id}).then((resp) => {
        this.ticket = resp.data.ticket
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    sendMessge() {
      if (this.$refs.ticket.validate()) {
        EventBus.$emit('setLoading', true)
        let data = new FormData();
        data.append('message', this.newMessage.message)
        if (this.newMessage.file) {
          data.append('file', this.newMessage.file)
        }
        this.requesting('ticket', 'sendMessage', {id: this.$route.params.id}, data).then(() => {
          EventBus.$emit('notify', {text: 'پیام شما ارسال شد و در انتظار پاسخ است.', color: 'green'})
          this.addModal = false
          this.$refs.ticket.reset()
        }).finally(() => {
          this.$nextTick().then(() => {
            this.getTicket(false)
          })
          EventBus.$emit('setLoading', false)
        })
      }

    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.getTicket()
    })
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 800px) {
  .message {
    width: 100% !important;
  }
}

.message {
  width: 500px;
  line-height: 30pt;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.userMessage {
  background: #eeeeee;
}

.adminMessage {
  background: #eeeeee;
  background: rgba(255, 134, 1, .2);
}

.dateTicket {
  font-size: 12px;
  color: grey;
  margin-right: 10px;
}
</style>
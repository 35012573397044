<template>
  <div class="fill-height">
    <v-dialog width="500px" v-model="expiredModal">
      <v-card>
        <v-card-text>
          <div class="text-center">
            <img src="../assets/subscription/expired_notify.png" width="50%" style="margin: 0px auto" alt="">
            <h3 class="primary--text font-weight-bold">
              حساب کاربری شما اشتراک فعالی ندارد
            </h3>
            <div class="mt-3 black--text">
              برای فعال سازی اشتراک خود از بخش پشتیبانی اقدام به ارسال تیکت کنید.
            </div>
            <div class="mt-4">
              <v-btn color="primary" elevation="0" small @click="rechargeAccount" class="white--text">
                فعال سازی اشتراک
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="500px" v-model="NearExpiredModal">
      <v-card>
        <v-card-text>
          <div class="text-center">
            <img src="../assets/subscription/expired_notify.png" width="50%" style="margin: 0px auto" alt="">
            <h3 class="primary--text font-weight-bold">
              اشتراک حساب کاربری شما رو به پایان است
            </h3>
            <div class="mt-3 black--text" style="line-height: 25pt">
              از اعتبار اشتراک شما
              <b>
                {{ SubscriptionLeft }}
                روز
              </b>
              مانده است.
              برای تمدید اشتراک خود از بخش پشتیبانی اقدام به ارسال تیکت کنید.
            </div>
            <div class="mt-4">
              <v-btn color="primary" elevation="0" small @click="rechargeAccount" class="white--text">
                تمدید اشتراک
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="fill-height pa-0 ma-0">
      <v-navigation-drawer app right v-model="drawer" v-if="$vuetify.breakpoint.mdAndDown">
        <div class="" style="  ">
          <div class="logoAreaNav text-center">
            <img src="../assets/logo/logo-site-iran.png" width="50%" alt="">
          </div>
          <div class="menuList">
            <div class="" v-for="(item ,i ) in menuItem" :key="i">
              <router-link :to="{name:item.routeName}">
                <div class="menuItem" :class="$route.name == item.routeName ? 'activeMenuItem' : 'NotActiveMenuItem'">
                  <div class="">
                    {{ item.title }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>

        </div>
      </v-navigation-drawer>
      <v-col cols="12" lg="2" class="pa-0 ma-0 drawerArea" v-if="$vuetify.breakpoint.mdAndUp">
        <div class="menuAnimation hiddenForAnimation" style="position: sticky;top: 20px ">
          <div class="logoAreaNav text-center">
            <img src="../assets/logo/logo-site-iran.png" width="50%" alt="">
          </div>
          <div class="menuList">
            <div class="" v-for="(item ,i ) in menuItem" :key="i">
              <router-link :to="{name:item.routeName}">
                <div class="menuItem" :class="$route.name == item.routeName ? 'activeMenuItem' : 'NotActiveMenuItem'">
                  <div class="">
                    {{ item.title }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="10" class="pa-0 ma-0">

        <div class="fill-height routerArea">
          <div class="profileNavArea hiddenForAnimation d-flex justify-center flex-column">
            <div class="d-flex ">
              <div class="routeName mt-3 font-weight-bold primary--text" style="font-family: bold !important;">
                {{ title }}
              </div>
              <v-spacer></v-spacer>
              <div class="ml-11" style="position: relative">
                <v-menu offset-y class="pa-0 ma-0">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="subscriptionCountDown" style="z-index: 1;top: 5px" v-if="showNotif">
                      {{notifications.length}}
                      اعلان
                    </div>
                    <v-btn v-bind="attrs"
                           v-on="on" fab elevation="0" outlined color="grey" small class="mt-1">
                      <v-icon color="grey">mdi-bell</v-icon>
                    </v-btn>
                  </template>
                  <v-list style="width: 300px !important;" class="pa-2 ma-0">
                    <template v-if="notifications.length != 0">
                      <v-list-item class="notificationItem" v-for="(item, i ) in notifications" :key="i">
                        <div class="pa-1 ">
                          <div class="pa-1  font-weight-bold titleNotification">
                            {{ item.title }}
                          </div>
                          <div class="dateNotification">
                            {{ item.created_at | filteringTime }}
                          </div>
                        </div>
                      </v-list-item>

                    </template>
                    <template v-else class="">
                      <div class="text-center font-weight-bold">
                        اعلان جدید وجود ندارد
                      </div>
                    </template>
                    <v-list-item class="">
                      <v-btn block color="primary" to="/dashboard/in-app-notification" small elevation="0" class="mt-4">
                        مشاهده همه
                      </v-btn>
                    </v-list-item>
                  </v-list>

                </v-menu>
              </div>
              <div class="accountArea d-flex mt-2">
                <div>
                  <v-menu offset-y class="pa-0 ma-0">
                    <template v-slot:activator="{ on, attrs }">
                      <div style="position: relative">
                        <img v-bind="attrs"
                             v-on="on" src="../assets/icons/account.png"
                             :width="$vuetify.breakpoint.mdAndUp ? '35px' : '30px'" alt="">
                        <div class="subscriptionCountDown">
                          {{ calcSubscription() }}
                        </div>
                      </div>
                    </template>
                    <v-list style="width: 180px !important;" class="pa-0 ma-0">
                      <v-list-item to="/dashboard/profile" class="">
                        <div class="profileMenuItem">
                          <v-icon>mdi-account</v-icon>
                          حساب کاربری
                        </div>
                      </v-list-item>
                      <v-list-item class="" style="border-top: 1px solid #ddd">
                        <v-btn @click="logout" class="white--text font-weight-bold" block color="red" small
                               style="font-family: bold !important;" elevation="0">
                          خروج از حساب کاربری
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

              </div>
              <div class="pt-2 mr-3" v-if="$vuetify.breakpoint.mdAndDown">
                <v-icon size="30" @click="drawer = true ">mdi-menu</v-icon>
              </div>
            </div>
          </div>
          <div class="" style="padding-top: 50px;padding-bottom: 40px">
            <v-alert type="mb-5"
                     v-if="!subscription"
                     style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
              <b>
                کاربر گرامی؛
              </b>
              <br>
              اشتراکی روی حساب کاربری شما فعال نیست. لطفا از بخش پشتیبانی اقدام به فعال سازی
              <b>اشتراک</b>
              کنید
            </v-alert>

            <v-fade-transition mode="out-in">
              <router-view/>
            </v-fade-transition>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {gsap} from "gsap";
import moment from "moment";

export default {
  data() {
    return {
      expiredModal: false,
      NearExpiredModal: false,
      sideBar: true,
      drawer: false,
      showNotif: true,
      menuItem: [
        {
          title: 'داشبورد',
          routeName: 'index'
        },
        {
          title: 'کلید‌واژه‌ها',
          routeName: 'keyword'
        },
        {
          title: 'گزارشات',
          routeName: 'report'
        },
        {
          title: 'اعلان ها',
          routeName: 'notifications'
        },
        {
          title: 'اشتراک ',
          routeName: 'subscriptions'
        },
        {
          title: 'پشتیبانی',
          routeName: 'support'
        },
        {
          title: 'ثبت بازخورد ',
          routeName: 'feedback'
        }
      ],
      SubscriptionLeft: null,
      notifications: [],
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  mounted() {

    this.checkSubscription()
    setTimeout(() => {
      // if (this.$vuetify.breakpoint.mdAndUp) {
      gsap.fromTo('.menuAnimation', {y: 100, opacity: 0}, {y: 0, opacity: 1}).then(() => {
        gsap.fromTo('.profileNavArea', {y: 100, opacity: 0}, {y: 0, opacity: 1})
      })
      // }
    }, 500)

    this.getUserNotification()

  },
  methods: {
    getUserNotification() {
      this.requesting('user', 'getNotification').then((resp) => {
        let notifications = resp.data.notifications
        notifications.forEach((item) => {
          console.log(this.isWithinLastThreeDays(item.created_at, 'notifications'))
          if (this.isWithinLastThreeDays(item.created_at)) {
            this.notifications.push(item); // Ensure you're using the correct "this.notifications" array
          }
        });
        if (this.notifications.length == 0) {
          this.showNotif = false
        }
      })
    },
    isWithinLastThreeDays(dateString) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for today

      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(today.getDate() - 3); // Subtract 3 days
      threeDaysAgo.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for 3 days ago

      const notificationDate = new Date(dateString);
      notificationDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for the notification date

      // Check if the notification date is between 3 days ago and today (inclusive)
      return notificationDate >= threeDaysAgo && notificationDate <= today;
    },
    calcSubscription() {
      if (!this.subscription) {
        return 'غیر فعال'
      }
      const expirationDate = moment(this.subscription.expired_at).startOf('day');
      //
      const today = moment().startOf('day');

      return expirationDate.diff(today, 'days') + ' روز ';

    },
    checkSubscription() {
      this.requesting('user', 'getValidSubscription').then((resp) => {
        console.log(this.$store.getters.getSubscription, 'subs')
        const expirationDate = moment(resp.data.subscription.expired_at).startOf('day');

        const today = moment('2024-08-26').startOf('day');

        const daysLeft = expirationDate.diff(today, 'days');

        if (daysLeft <= 3) {
          this.NearExpiredModal = true
          this.SubscriptionLeft = daysLeft
        }

      }).catch(() => {
        this.expiredModal = true
      })
    },
    rechargeAccount() {
      this.expiredModal = false
      this.$router.push('/dashboard/support')
    }
  }
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 800px) {
  .routerArea {
    padding: 10px !important;
  }
}

.titleNotification {
  font-size: 13px;
}

.dateNotification {
  color: grey;
  font-size: 11px;
}

.notificationItem {
  border-bottom: 1px solid #dddddd;
}

.subscriptionCountDown {
  font-size: 12px;
  width: 60px;
  padding: 2px;
  position: absolute;
  top: -5px;
  text-align: center;
  border-radius: 15px;
  background: #ff8601;
  font-weight: bolder;
  right: -40px;
}

.profileMenuItem {
  font-size: 14px;
}

.profileNavArea {
  position: sticky;
  top: 20px;
  background: white;
  left: 100px;
  right: 100px;
  padding: 5px 20px;
  border-radius: 5px;
  box-shadow: 0 .125rem .5rem 0 rgba(47, 43, 61, .12);
  z-index: 4;
}

.blurArea {
  background: black;
  position: sticky;
  top: 20px;
  right: 0px;
  left: 0px;
  z-index: 1;
}

.menuItem {
  display: flex;
  font-size: 12pt;
  padding: 10px;
  margin-bottom: 10px;
  color: #444050;
  transition: all 0.3s;
}


.menuList {
  margin: 20px 35px 0px 35px;
}

.NotActiveMenuItem:hover {
  border-radius: 10px;
  background: #ededed;
  transition: all 0.3s;
}

.activeMenuItem {
  background: #ff8601;
  border-radius: 10px;
  color: white;
  font-weight: bolder;

}

.logoAreaNav {
  margin: 20px 20px;
}

a {
  color: inherit !important;
  text-decoration: none;
}

.routerArea {
  background: #f8f7fa;
  z-index: 1;
  padding: 0px 100px;
  position: relative;
}

.drawerArea {
  position: relative;
  box-shadow: 0px .125rem .5rem 0 rgba(47, 43, 61, .12);
  z-index: 2;
  /*box-shadow: -10px 10px 10px 0px blue !important;*/
}
</style>
<template>
  <div>
    <div class="borderBottomBox">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          گزارشات
        </div>
        <div>
          <v-btn color="secondary" small elevation="0" to="/dashboard/reports/add" class="font-weight-bold" style="border-radius: 10px">
            <v-icon class="ml-1">mdi-plus</v-icon>
            ایجاد
          </v-btn>
        </div>
      </div>

      <div class="mt-5">
        <v-data-table :headers="headers" :items="reports" disable-pagination hide-default-footer>
          <template v-slot:item.created_at="{item}">
            {{ item.created_at | filteringTime }}
          </template>
          <template v-slot:item.type="{item}">
            <template v-if="item.type =='excel'">
              اکسل
            </template>
            <template v-else-if="item.type == 'pdf'">
              فایل
            </template>
            <template v-else-if="item.type == 'csv'">
              csv
            </template>
          </template>
          <template v-slot:item.file_name="{item}">
            <div style="direction: ltr !important;text-align: right">
              {{ item.file_name }}
            </div>
          </template>
          <template v-slot:item.actions="{item}">
            <a :href="item.file_url" target="_blank">
              <v-btn class="ma-5 tableBtn" elevation="0" small>
                <v-icon small>mdi-download</v-icon>
                دانلود
              </v-btn>
            </a>
          </template>
        </v-data-table>
      </div>

    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      reports: [],
      headers: [
        {text: 'کد', value: 'id'},
        {text: 'نام گزارش', value: 'file_name'},
        {text: 'نوع گزارش', value: 'type'},
        {text: 'تاریخ ایجاد', value: 'created_at'},
        {text: '', value: 'actions'}
      ],
    }
  },
  methods: {
    getReports() {
      EventBus.$emit('setLoading', true)
      this.requesting('report', 'getReports').then((resp) => {
        this.reports = resp.data.reports
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getReports()
  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div>
    <div class="borderBottomBox   notification">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          اعلانات و اخبار
        </div>
      </div>
      <div class="notificationArea mt-5">
        <div class="notificationItem" v-for="(item , i) in notifications" :key="i">
          <div class="titleNotification">
            {{ item.title }}
          </div>
          <div class="dateNotification">
            {{ item.created_at | filteringTime }}
          </div>
          <div class="bodyNotification">
            {{ item.body }}
          </div>
          <div class="actionNotification mt-3" v-if="item.url">
            <a :href="item.url" target="_blank">
              <v-btn class="tableBtn" elevation="0" x-small>
                اطلاعات بیشتر
              </v-btn>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      feedback: null,
      notifications: [],
    }
  },

  methods: {
    getUserNotification() {
      this.requesting('user', 'getNotification').then((resp) => {
        this.notifications = resp.data.notifications
      })
    },
    load() {
      setTimeout(() => {
        gsap.fromTo('.notification', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
        }).then(() => {
        })
      }, 500)
    },
  },
  mounted() {
    // this.load()
    this.getUserNotification()
  }
}
</script>

<style lang="scss" scoped>
.notificationItem {
  border-radius: 10px;
  border: 1px solid #dddddd;
  padding: 10px;
  margin-bottom: 20px;
}

.titleNotification {
  font-family: bold !important;
  font-size: 18px;
}

.bodyNotification {
  font-size: 14px;
  line-height: 20pt;
}

.dateNotification {
  font-size: 12px;
  color: gray;
  margin-top: 5px;
}
</style>
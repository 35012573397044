<template>
  <div>
    <div v-if="load"  class="chart-container fill-height">
      <apexchart style="flex-grow: 1" type="pie" width="81%" :options="chartOptions" :series="sentiment_series"></apexchart>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      sentiment_series: [],
      load: false,
      chartOptions: {
        chart: {
          width: '100%',
          height: '100%',
          type: 'pie',
        },
        labels: [
          'مثبت',
          'طبیعی',
          'منفی',
        ],
        colors: ['#00E396', '#ddd', '#FF4560'], // Custom colors for each label
        theme: {
          monochrome: {
            enabled: false, // Disable monochrome to use custom colors
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
            },
          },
        },
        grid: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + '%'];
          },
        },
        legend: {
          show: true,
        },
      },

    }
  },
  computed: {
    frozenChartOptions() {
      // Freeze the chart options to prevent reactivity issues
      return Object.freeze(this.chartOptions);
    },
  },
  props: {
    sentiment_chart: Array,
  },
  methods: {
    prepareSentimentChartData() {
      let total = 0
      this.sentiment_series = []

      this.sentiment_chart.forEach((item) => {
        if (['negative', 'positive', 'neutral'].includes(item.sentiment)) {
          total += item.total
        }
      });

      const sentiments = {
        positive: 0,
        neutral: 0,
        negative: 0,
      };

      this.sentiment_chart.forEach((item) => {
        const sentiment = item.sentiment;
        if (sentiments[sentiment] !== undefined) {
          sentiments[sentiment] = ((item.total / total) * 100).toFixed(2);
        }
      });

      this.sentiment_series = [
        Number(sentiments.positive),
        Number(sentiments.neutral),
        Number(sentiments.negative)
      ];

      this.$nextTick().then(() => {
        this.load = true;
      });
    },
  },
  mounted() {
    this.prepareSentimentChartData();
  }
}
</script>

<style scoped>
.chart-container {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center;    /* Vertically center */
}
</style>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    rtl:true,
    theme: {
        dark:false,
        themes: {
            light: {
                primary: '#ff8601',
                secondary: '#017aff',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
            dark:{
                primary: '#ff8601',
                'secondPrimary': '#39475d',
                'blue':'#1a1d24'
            }
        },
    },
});

<template>
  <div>
    <v-dialog width="500" v-model="openModalAddEmail">
      <v-card>
        <v-card-title class="primary white--text font-weight-bold">
          افزودن ایمیل
        </v-card-title>
        <v-card-text>
          <v-form ref="addEmail" class="mt-10" v-if="openModalAddEmail" @submit.prevent
                  @keypress.native.enter="addEmail">
            <div class="ltrForm">
              <v-text-field v-model="email" :rules="rules.emailRules" outlined dense label="ایمیل"></v-text-field>
            </div>
          </v-form>
          <v-btn color="primary" elevation="0" @click="addEmail">
            افزودن
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="500" v-model="openModalAddTelegramAccount">
      <v-card>
        <v-card-title class="primary white--text font-weight-bold">
          افزودن کاربر تلگرام
        </v-card-title>
        <v-card-text class="mt-5">
          <div>
            <b style="font-size: 15px">
              برای افزودن کاربر تلگرام مراحل زیر را طی کنید:
            </b>
            <div class="mt-3">
              1 - بات تلگرام با ایدی
              <a href="https://t.me/lunaslBot">
                <div style="direction: ltr !important;display: inline-block;font-weight: bolder">
                  @lunaslBot
                </div>
              </a>
              را استارت کنید
            </div>
            <div class="mt-3">
              2 - توکن زیر را برای بات در قالب یک پیام ارسال کنید:
              <v-alert type=" mt-5"
                       style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
                <div class="d-flex ">
                  <div @click="copyToClipboard(token)">
                    <v-icon color="primary" style="cursor: pointer">
                      mdi-file-multiple
                    </v-icon>
                  </div>
                  <div class="tokenArea" style="direction: ltr!important;">
                    {{ token }}
                  </div>
                </div>

              </v-alert>

            </div>
            <div class="mt-3">
              3 - بعد از ارسال پیام این صفحه را رفرش کنید تا کاربر را ببینید.
            </div>
          </div>
          <v-btn color="primary" class="mt-5" elevation="0" small @click="openModalAddTelegramAccount = false">
            بستن
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="borderBottomBox hiddenForAnimation  notification">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          مدیریت اعلان ها
        </div>
      </div>
      <v-alert type=" mt-5" style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
        <b>
          کاربر گرامی؛
        </b>
        <br>
        زمانی که دیتا روی کلمات شما دریافت می کنیم، از روش های زیر به شما اطلاع رسانی خواهیم کرد.
      </v-alert>
    </div>
    <div class="borderBottomBox mt-10 hiddenForAnimation  notification">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          ایمیل
        </div>
        <div>
          <v-btn color="primary" :disabled="!canAddEmailSub" elevation="0" small @click="openModalAddEmail = true ">
            <v-icon>mdi-plus</v-icon>
            افزودن
          </v-btn>
        </div>
      </div>
      <v-alert type=" mt-5 mb-5"
               v-if="subscription_error_email"
               style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
        <b>
          کاربر گرامی؛
        </b>
        <template v-if="subscription_error_email == 'no_subscription'">
          <br>
          اشتراکی روی حساب کاربری شما فعال نیست. لطفا از بخش پشتیبانی اقدام به فعال سازی
          <b>اشتراک</b>
          کنید
        </template>
        <template v-else-if="subscription_error_email == 'subscription_limit'">
          <br>
          شما به
          <b>
            محدویت تعداد ایمیل های دریافت کننده
          </b>
          خود رسیده اید. می توانید در صورت نیاز از بخش پشتیبانی اقدام به ارتقای
          اشتراک خود کنید.
        </template>
      </v-alert>


      <v-data-table show-expand :expanded.sync="email_expanded" disable-pagination hide-default-footer
                    :headers="headers" :items="emails">
        <template v-slot:item.status="{item}">
          <v-switch v-model="item.status" :value="1" @click="changeEmailStatus(item.status === 1,item.id)" dense flat
                    inset></v-switch>
        </template>
        <template v-slot:item.action="{item}">
          <v-btn fab color="red" class="white--text" x-small elevation="0" @click="deleteEmail(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{item}">
          <td :colspan="12">
            <div style="width: 100%" class="mb-10 mt-2">
              <div class="keywordsArea d-flex">
                <div :class="isKeywordActive(item.accessibleKeyword,keyword.id) ? 'ActiveKeywordItem' : 'keywordItem'"
                     @click="isKeywordActive(item.accessibleKeyword,keyword.id) ? deleteKeywordFromEmail(item.accessibleKeyword,keyword.id) : addKeywordToEmail(item.id,keyword.id)"
                     v-for="(keyword , i) in keywords" :key="i">
                  {{ keyword.pattern }} - {{ keyword.social_media.name }}
                </div>
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="borderBottomBox mt-10 hiddenForAnimation  notification">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          تلگرام
        </div>
        <div>
          <v-btn :disabled="!canAddTelegramAccountSub" color="primary" elevation="0" small
                 @click="openModalAddTelegramAccount = true ">
            <v-icon>mdi-plus</v-icon>
            افزودن
          </v-btn>
        </div>
      </div>

      <v-alert type=" mt-5 mb-5"
               v-if="subscription_error_bot"
               style="background:rgba(255, 134, 1,0.1);color: #ff8601;font-size: 12px;line-height: 15pt">
        <b>
          کاربر گرامی؛
        </b>
        <template v-if="subscription_error_bot == 'no_subscription'">
          <br>
          اشتراکی روی حساب کاربری شما فعال نیست. لطفا از بخش پشتیبانی اقدام به فعال سازی
          <b>اشتراک</b>
          کنید
        </template>
        <template v-else-if="subscription_error_bot == 'subscription_limit'">
          <br>
          شما به
          <b>
            محدویت تعداد کاربران تلگرام دریافت کننده
          </b>
          خود رسیده اید. می توانید در صورت نیاز از بخش پشتیبانی اقدام به ارتقای
          اشتراک خود کنید.
        </template>
      </v-alert>

      <v-data-table show-expand :expanded.sync="expanded" disable-pagination disable-filtering hide-default-footer
                    :headers="telegram_headers"
                    :items="telegram_accounts">
        <template v-slot:item.status="{item}">
          <v-switch v-model="item.status" :value="1" @click="changeTelegramAccountStatus(item.status === 1,item.id)"
                    dense flat
                    inset></v-switch>
        </template>
        <template v-slot:item.action="{item}">
          <v-btn fab color="red" class="white--text" x-small elevation="0" @click="deleteTelegramAccount(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{item}">
          <td :colspan="12">
            <div style="width: 100%" class="mb-10 mt-2">
              <div class="keywordsArea d-flex">
                <div :class="isKeywordActive(item.accessible_keyword,keyword.id) ? 'ActiveKeywordItem' : 'keywordItem'"
                     @click="isKeywordActive(item.accessible_keyword,keyword.id) ? deleteKeywordFromAccount(item.accessible_keyword,keyword.id) : addKeywordToAccount(item.id,keyword.id)"
                     v-for="(keyword , i) in keywords" :key="i">
                  {{ keyword.pattern }} - {{ keyword.social_media.name }}
                </div>
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="borderBottomBox mt-10 hiddenForAnimation  notification">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          WebHook
          <v-chip color="primary" small class="mr-2">بزودی</v-chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";
/*eslint-disable*/

export default {
  data() {
    return {

      expanded: [],
      email_expanded: [],
      openModalAddEmail: false,
      openModalAddTelegramAccount: false,
      emails: [],
      headers: [
        {
          text: 'کد',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'ایمیل',
          align: 'center',
          sortable: false,
          value: 'email',
        },
        {
          text: 'وضعیت',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      telegram_headers: [
        {
          text: 'کد',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'نام اکانت',
          align: 'center',
          sortable: false,
          value: 'telegram_user_firstname',
        },
        {
          text: 'آی دی اکانت',
          align: 'start',
          sortable: false,
          value: 'telegram_username',
        },
        {
          text: 'وضعیت',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      email: null,
      telegram_accounts: [],
      subscription_error_email: null,
      subscription_error_bot: null,
      keywords: null,
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    canAddEmailSub() {
      this.subscription_error_email = null
      if (!this.subscription) {
        this.subscription_error_email = 'no_subscription'
        return false
      }
      let subscription_details = this.subscription.subscription
      if (!subscription_details.email_limit_count) {
        return true
      } else {
        if (this.emails) {
          if (this.emails.length >= subscription_details.email_limit_count) {
            this.subscription_error_email = 'subscription_limit'
            return false
          } else {
            return true
          }
        }
      }
      return false
    },
    canAddTelegramAccountSub() {
      this.subscription_error_bot = null
      if (!this.subscription) {
        this.subscription_error_bot = 'no_subscription'
        return false
      }
      let subscription_details = this.subscription.subscription
      if (!subscription_details.telegram_account_limit_count) {
        return true
      } else {
        if (this.emails) {
          if (this.telegram_accounts.length >= subscription_details.telegram_account_limit_count) {
            this.subscription_error_bot = 'subscription_limit'
            return false
          } else {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    deleteKeywordFromAccount(accessible_keyword, keyword_id) {
      let target_id = null
      accessible_keyword.forEach((item) => {
        if (item.id == keyword_id) {
          target_id = item.pivot.id
        }
      })
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'detachKeywordFromTelegramAccount', {}, {
        accessible_keyword_id: target_id,
      }).then(() => {
        this.getTelegramAccount()
        EventBus.$emit('notify', {text: 'کلیدواژه از این کاربر حذف شد.', color: 'green'})
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    deleteKeywordFromEmail(accessible_keyword, keyword_id) {
      let target_id = null
      accessible_keyword.forEach((item) => {
        if (item.id == keyword_id) {
          target_id = item.pivot.id
        }
      })
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'detachKeywordFromEmail', {}, {
        accessible_keyword_id: target_id,
      }).then(() => {
        this.getEmails(false)
        EventBus.$emit('notify', {text: 'کلیدواژه از این کاربر حذف شد.', color: 'green'})
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    addKeywordToAccount(telegram_account_id, keyword_id) {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'addKeywordToTelegramAccount', {}, {
        "keyword_ids": [keyword_id],
        "telegram_account_id": telegram_account_id
      }).then(() => {
        this.getTelegramAccount()
        EventBus.$emit('notify', {text: 'کلیدواژه به این کاربر اضافه شد.', color: 'green'})
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    addKeywordToEmail(email_id, keyword_id) {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'addKeywordToEmail', {}, {
        "keyword_ids": [keyword_id],
        "user_email_id": email_id
      }).then(() => {
        this.getEmails(false)
        EventBus.$emit('notify', {text: 'کلیدواژه به این کاربر اضافه شد.', color: 'green'})
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    isKeywordActive(accessible_keyword, keyword_id) {
      let can = false
      accessible_keyword.forEach((item) => {
        if (item.id == keyword_id) {
          can = true
        }
      })
      return can;
    },
    getKeywords() {
      EventBus.$emit('setLoading', true)
      this.requesting('keyword', 'getKeywords').then((resp) => {
        this.data = null
        this.$nextTick().then(() => {
          this.keywords = resp.data.keywords
        })
      })
    },
    addEmail() {
      if (this.$refs.addEmail.validate()) {
        EventBus.$emit('setLoading', true)
        this.requesting('user', 'addEmail', {}, {
          email: this.email
        }).then(() => {
          EventBus.$emit('notify', {text: 'ایمیل اضافه شد', color: 'green'})
          this.getEmails(false)
          this.openModalAddEmail = false
          this.$refs.addEmail.reset()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
    deleteEmail(id) {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'deleteEmail', {id}).then(() => {
        EventBus.$emit('notify', {text: 'ایمیل حذف شد', color: 'green'})
        this.getEmails(false)
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    deleteTelegramAccount(id) {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'DeleteTelegramAccount', {id}).then(() => {
        EventBus.$emit('notify', {text: 'کاربر تلگرام حذف شد', color: 'green'})
        this.getTelegramAccount(false)
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    changeEmailStatus(status, id) {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'changeStatusEmail', {id}, {status}).then(() => {
        EventBus.$emit('notify', {text: 'وضعیت ایمیل تغیر کرد.', color: 'green'})
        this.getEmails(false)
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    changeTelegramAccountStatus(status, id) {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'changeStatusTelegramAccount', {id}, {status}).then(() => {
        EventBus.$emit('notify', {text: 'وضعیت کاربر تلگرام تغیر کرد.', color: 'green'})
        this.getTelegramAccount(false)
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    load() {
      setTimeout(() => {
        gsap.fromTo('.notification', {y: 100, opacity: 0,}, {
          y: 0,
          opacity: 1,
        }).then(() => {
        })
      }, 500)
    },
    getEmails(load = true) {
      this.subscription_error_bot = null
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'emailList').then((resp) => {
        this.emails = resp.data.useremail
      }).then(() => {
        this.emails.forEach((item) => {
          this.email_expanded.push(item)
        })
        if (load) {
          this.load()
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    getTelegramAccount() {
      this.expanded = []
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'telegramAccount').then((resp) => {
        this.telegram_accounts = resp.data.user_accounts
        this.telegram_accounts.forEach((item) => {
          this.expanded.push(item)
        })
      }).then(() => {

      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.getEmails()
      this.getTelegramAccount()
      this.getKeywords()
    })
  }
}
</script>

<style>
.keywordItem {
  border: 1px solid #dddddd;
  padding: 5px;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.keywordItem:hover {
  background: #eee;
  transition: all 0.3s;
}

.ActiveKeywordItem:hover {
  background: #eee;
  transition: all 0.3s;
}

.ActiveKeywordItem {
  transition: all 0.3s;
  border: 1px solid #ff8601;
  padding: 5px;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 12px;
  color: #ff8601;
  font-weight: bolder;
  cursor: pointer;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}

.tokenArea {
  padding-right: 10px;
  word-wrap: break-word; /* Old name, but still widely supported */
  overflow-wrap: break-word; /* New name for word-wrap */
  word-break: break-word;
}
</style>
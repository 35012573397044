<template>
  <div class="fill-height" style="overflow:hidden">
    <div class="fill-height pa-0 ma-0 loginContext">
      <v-row class="fill-height pa-0 ma-0">
        <v-col cols="12" lg="4" class="white pa-0 ma-0 fill-height">
          <div class="formContext fill-height">
            <div class="formArea hiddenForAnimation">
              <div class="text-center">
                <img src="../../assets/logo/logo-site-iran.png" width="30%" alt="">
                <div class="welcomeSection mt-3">
                  لونا؛ ابزار رصد و پایش شبکه‌های اجتماعی
                </div>
                <p class="mt-1">
                  <b>
                    لطفا
                  </b>
                  قبل از صحبت کردن گوش کنید
                </p>
              </div>
              <div style="width: 100%;height: 1px;background: #d1d0d4;opacity: 0.4"></div>
              <div class="mt-5">
                <v-form ref="login" @keypress.native.enter="login">
                  <v-text-field v-model="email" :rules="rules.emailRules" label="ایمیل : " dense outlined
                                style=""></v-text-field>
                  <v-text-field type="password" v-model="password" :rules="rules.required" label="رمز عبور : " dense
                                outlined
                                style=""></v-text-field>
                </v-form>
                <v-btn @click="login" color="primary" block elevation="0" class="font-weight-bold mt-2">
                  ورود
                </v-btn>

                <div class="d-flex justify-center mt-3">
                  <div class="mt-4" style="font-size: 13px">
                    رمز عبور خود را فراموش کردید؟
                    <router-link to="/forget-password">
                      <span class="primary--text font-weight-bold mr-2">
                       بازیابی رمز عبور
                      </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="8" v-if="$vuetify.breakpoint.mdAndUp" class="vectorArea pa-0 ma-0 fill-height">
          <div class="bottomImage hiddenForAnimation">
            <img src="../../assets/images/bg-shape-image-light.png" alt="">
          </div>
          <div class="vectorImage hiddenForAnimation">
            <img src="../../assets/images/46.png" width="350px" alt="">
          </div>
          <!--          <div class="logoArea hiddenForAnimation">-->
          <!--          </div>-->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      email: null,
      password: null,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$vuetify.breakpoint.mdAndDown) {
        gsap.fromTo('.formArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5})
      } else {
        gsap.fromTo('.bottomImage', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
          gsap.fromTo('.vectorImage', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
            gsap.fromTo('.logoArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5}).then(() => {
              gsap.fromTo('.formArea', {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.5})
            })
          })
        })
      }
    }, 500)
  },
  methods: {
    login() {
      if (this.$refs.login.validate()) {
        EventBus.$emit('setLoading', true)
        this.requesting('auth', 'login', {}, {
          email: this.email,
          password: this.password,
        }).then(() => {
          EventBus.$emit('notify', {
            color: 'green',
            text: 'خوش‌آمدید ',
            type: 'welcome'
          })
          setTimeout(() => {
            this.$router.push('/dashboard')
          }, 500)
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    }
  }
}
</script>

<style>
a {
  text-decoration: none;
}

.vectorArea {
  background: #f8f7fa;
  position: relative;
}

.welcomeSection {
  font-size: 14pt;
  font-family: bold !important;
  color: #444050;
}

.formArea p {
  color: #444050;
}

.formArea .v-text-field--outlined fieldset {
  border-color: #d1d0d4;
}

.formContext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formArea {
  width: 60%;
  margin: 0px auto;
}

.bottomImage {
  position: absolute;
  bottom: -120px;
  right: 0px;
  left: 0px;
}

.bottomImage img {
  width: 100%;
}

.vectorImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoArea {
  position: absolute;
  top: 50px;
  right: 20px;
}

.logoArea img {
  width: 100px !important;
}
</style>
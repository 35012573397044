<template>
  <div>
    <v-fade-transition mode="out-in">
      <div :key="1" v-if="!keywords">
        <div class="text-center mt-10">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <div>
            درحال دریافت اطلاعات
          </div>
        </div>
      </div>
      <div :key="2" v-else>
        <div class="deckDashboard">
          <div class="deckItem" v-for="(keyword , i) in keywords" :key="i">
            <div class="headerDeckItem">
              <div class="headerDeckItemKeyword">
                {{ keyword.pattern }}
                <div class="headerDeckItemKeywordCount">
                  {{ keyword.data_count }}
                  دیتای دریافتی
                </div>
              </div>
              <div class="headerDeckItemSocial">
                <img style="border-radius: 5px"
                     :src="require(`../../../assets/social/${keyword.social_media.key}.png`)"
                     width="40px" class="mt-1" alt="">
              </div>
            </div>
            <div class="paginateArea d-flex justify-lg-space-between mb-3">
              <v-btn x-small class="tableBtn" elevation="0"
                     :disabled="keyword_page[keyword.id].page == 1"
                     @click="getKeywordData(keyword.id,(keyword_page[keyword.id].page - 1))"
              >
                قبلی
              </v-btn>
              <v-btn x-small class="tableBtn" elevation="0"
                     :disabled="keyword_page[keyword.id].page == keyword_page[keyword.id].total"
                     @click="getKeywordData(keyword.id,(keyword_page[keyword.id].page + 1))">
                بعدی
              </v-btn>
            </div>
            <v-fade-transition mode="out-in">
              <div :key="1" class="deckData" v-if="keyword_data[keyword.id] != 'loading'">
                <div v-for="(item , n ) in keyword_data[keyword.id]" :key="n" class="mb-2">
                  <SimpleDataBox :id="item.id" :load_highlight="false" :keyword="keyword.pattern" :data="item"/>
                </div>
              </div>
              <div v-else>
                <div :key="2" class="text-center">
                  <v-progress-circular indeterminate size="20" color="primary"></v-progress-circular>
                </div>
              </div>
            </v-fade-transition>
          </div>
        </div>
      </div>

    </v-fade-transition>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import SimpleDataBox from "@/components/Data/SimpleDataBox";

export default {
  components: {SimpleDataBox},

  data() {
    return {
      keywords: null,
      keyword_data: {},
      keyword_page: {},
      load_data: false,
      paginate_id: null,
    }
  },
  methods: {
    getKeywords() {
      EventBus.$emit('setLoading', true)
      this.requesting('keyword', 'getKeywords').then((resp) => {
        this.data = null
        this.$nextTick().then(() => {
          this.keywords = resp.data.keywords
          this.keywords.forEach((item) => {
            this.getKeywordData(item.id)
          })
        })

      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    getKeywordData(id, page = 1) {
      this.$set(this.keyword_data, id, 'loading');
      this.requesting('keyword', 'getKeywordData', {
        page: page,
        id: id,
      }, {}).then((resp) => {
        this.load_data = true
        this.$nextTick().then(() => {
          this.$set(this.keyword_data, id, resp.data.data);
          this.$set(this.keyword_page, id, {
            page: resp.data.pagination.current_page,
            total: resp.data.pagination.total_pages,
          });
        });
      }).finally(() => {
        this.paginate_id = null
      })
    },
    handlePaginate(id, newPage) {
      this.paginate_id = id
      this.getKeywordData(id, newPage)
    }
  },
  mounted() {
    this.getKeywords()
  }
}
</script>

<style scoped>
.deckItem {
  border-radius: 10px;
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  margin: 0px 10px;
  padding: 20px;
}

.deckDashboard {
  padding: 20px;
  height: 800px;
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
}

.headerDeckItem {
  border-bottom: 1px solid #ff8601;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
}

.headerDeckItemKeyword {
  font-family: bold !important;
}

.headerDeckItemSocial {
  width: 25px;
  height: 25px;
}

.headerDeckItemKeywordCount {
  color: gray;
  font-size: 12px;
}

.headerDeckItemSocial img {
  width: 100%;
}
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import APIResolver from "../../Lib/APIResolver";

Vue.use(Vuex)
/*eslint-disable*/
export default new Vuex.Store({
    state: {
        baseUrl: 'http://localhost:8000/api/v1/',
        primaryPicture: null,
        token: localStorage.getItem('token'),
        content: null,
        profile: localStorage.getItem('profile'),
        subscription: null,
    },
    getters: {
        getBaseUrl: state => state.baseUrl,
        getContent: state => state.content,
        getToken: state => state.token,
        getSubscription: state => state.subscription
    },
    mutations: {
        SET_PROFILE(state, payload) {
            state[payload.key] = payload.data
        },
        SET_SUBSCRIPTION(state, payload) {
            state[payload.key] = payload.data
        },
        SET_TOKEN(state, payload) {
            state[payload.key] = payload.data
            localStorage.setItem('token', payload.data)
            console.log(state)
        },
        setContent(state, payload) {
            state.content = payload
        }
    },
    actions: {
        generalRequest(context, payload) {
            return new APIResolver().sendRequest(payload.type, payload.name, payload.paramsQuery, payload.body, payload.headers)
        }
    },
    modules: {}
})

<template>
  <div>
    <v-dialog width="500px" v-model="openModalFilter">
      <FilterSection @applyFilter="applyFilters($event)" @resetFilter="resetFilter"/>
    </v-dialog>
    <div class="borderBottomBox">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          ایجاد گزارش
        </div>
        <v-btn v-if="state == 'select-data'" @click="openModalFilter = true" small color="primary" elevation="0"
               class="font-weight-bold mr-2">
          فیلتر
          <v-icon>
            mdi-filter
          </v-icon>

        </v-btn>
      </div>
      <div class="generateReportBox">
        <v-fade-transition mode="out-in">
          <div :key="1" v-if="loader">
            <div class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              <div class="mt-3 font-weight-bold">
                درحال دریافت اطلاعات
              </div>
            </div>
          </div>
          <div key="2" v-else>
            <div v-if="state == 'select-name'">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-form ref="addReport">
                    <v-text-field v-model="name" :rules="rules.required" label="نام گزارش" outlined
                                  dense></v-text-field>
                    <v-select v-model="type" :rules="rules.required" :items="types" outlined dense
                              label="نوع گزارش"></v-select>
                  </v-form>
                </v-col>
              </v-row>
              <v-btn color="primary" elevation="0"
                     @click="submitNameAndType"
                     class="font-weight-bold mr-2" small>
                بعدی
              </v-btn>

            </div>
            <div v-if="state == 'select-keyword'">
              <v-row>
                <v-col class="keywordItem mb-5 d-flex justify-space-between" cols="12" lg="3"
                       @click="selectKeyword(keyword.id)"
                       v-for="(keyword , i) in keywords" :key="i">
                  <div>
                    <div style="font-weight: bolder">
                      {{ keyword.pattern }}
                    </div>
                    <div style="font-size: 10px">
                      {{ keyword.data_count }}
                      دیتای دریافتی
                    </div>
                  </div>
                  <div style="font-size: 13px" class="d-flex flex-column justify-center">
                    {{ keyword.social_media.name }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="state == 'select-data'">
              <div class="nextBtn" @click="generateReport">
                ایجاد گزارش
              </div>
              <v-data-table
                  item-value="id"
                  :headers="headers"
                  v-model="selectedData"
                  show-select
                  :items="data"
                  disable-pagination
                  hide-default-footer
              >
                <template v-slot:item.content="{item}">
                  <div class="contentItem ">
                    {{ item.content }}
                  </div>
                </template>
                <template v-slot:item.sentiment="{item}">
                  {{ sentimentFiltering(item.sentiment) }}
                </template>
                <template v-slot:item.created_at="{item}">
                  <div class="pa-5">
                    {{ item.created_at | createdAtFilter }}
                  </div>
                </template>
              </v-data-table>
            </div>
            <div v-else-if="state == 'get-report'">
              <div class="text-center">
                <v-btn color="green" class="white--text mb-3" fab elevation="0">
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>
                <div style="font-family: bold !important;">
                  گزارش شما اماده است.
                </div>
                <div style="font-size: 15px;margin-top: 5px">
                  با استفاده از دکمه زیر میتوانید گزارش خود را دانلود کنید.
                </div>
                <div>
                  <a :href="report.file_url" target="_blank">
                    <v-btn class="tableBtn mt-3" elevation="0" small>
                      <v-icon small>mdi-download</v-icon>
                      دانلود
                    </v-btn>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </v-fade-transition>

      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import moment from "moment-timezone";
import momentJalali from "moment-jalaali";
import FilterSection from "@/components/Data/FilterSection.vue";

export default {
  components: {FilterSection},
  data() {
    return {
      types: [
        {
          value: 'pdf',
          text: 'فایل'
        },
        {
          value: 'excel',
          text: 'اکسل'
        },
        {
          value: 'csv',
          text: 'csv'
        },
      ],
      openModalFilter: false,
      selectedData: [],
      headers: [
        {text: 'کد', value: 'id'},
        {text: 'نویسنده', value: 'author'},
        {text: 'محتوا', value: 'content'},
        {text: 'تحلیل رفتار کاربر', value: 'sentiment'},
        {text: 'شبکه اجتماعی', value: 'social_media.name'}, // Nested value
        {text: 'تاریخ انتشار', value: 'created_at'},
      ],
      user_keyword_id: null,
      state: 'select-name',
      keywords: null,
      loader: false,
      data: null,
      report: null,
      name: null,
      type: null,
    }
  },
  methods: {
    submitNameAndType() {
      if (this.$refs.addReport.validate()) {
        if (this.$route.query.keyword_id) {
          this.user_keyword_id = this.$route.query.keyword_id
          this.selectKeyword(this.user_keyword_id)
        } else {
          this.getKeywords()
        }
      }
    },
    applyFilters(filters) {
      this.openModalFilter = false
      this.page = 1
      this.$nextTick().then(() => {
        this.selectKeyword(this.user_keyword_id, filters)
      })
    },
    resetFilter() {
      this.openModalFilter = false
      this.selectKeyword(this.user_keyword_id, {})
    },
    generateReport() {
      if (this.selectedData.length == 0) {
        EventBus.$emit('notify', {text: 'حداقل یک دیتا را انتخاب کنید.', color: 'red'})
        return;
      }
      this.loader = true
      let temp = []
      this.selectedData.forEach((item) => {
        temp.push(item.id)
      })
      this.requesting('report', 'generateReport', {type: this.type}, {
        "data_ids": temp,
        "user_keyword_id": this.user_keyword_id,
        'name': this.name,
      }).then((resp) => {
        this.report = resp.data.report
        this.state = 'get-report'
      }).finally(() => {
        this.loader = false
      })
    },
    selectKeyword(keyword_id, filters = {}) {
      this.user_keyword_id = keyword_id
      this.loader = true
      this.getKeywordData(keyword_id, filters)

    },
    getKeywordData(keyword_id, filters = {}) {
      this.requesting('keyword', 'getKeywordDataWithoutPaginate', {id: keyword_id}, filters).then((resp) => {
        this.data = resp.data.data
        this.state = 'select-data'
      }).finally(() => {
        this.loader = false
      })
    },
    getKeywords() {
      this.loader = true
      this.requesting('keyword', 'getKeywords').then((resp) => {
        this.keywords = resp.data.keywords
        this.state = 'select-keyword'
      }).then(() => {
        this.loader = false
      })
    }
  },
  filters: {
    createdAtFilter(val) {
      const tehranTime = moment(val).tz('Asia/Tehran');
      return momentJalali(tehranTime).format('HH:mm - jYYYY/jMM/jDD');
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.generateReportBox {
  //border: 1px solid #dddddd;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.keywordItem {
  font-size: 15px;
}

.contentItem {
  height: 50px;
  overflow: hidden;
}

.nextBtn {
  position: fixed;
  bottom: 10px;
  z-index: 1;
  background: #ff8601;
  width: 120px;
  text-align: center;
  font-size: 13px;
  padding: 10px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-family: bold !important;
  cursor: pointer;
}
</style>
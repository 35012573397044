const baseUrl = 'user/'
/*eslint-disable*/
export default [
    {
        name: "getProfile",
        path: baseUrl + "profile",
        method: "get",
        loading: true,
        commits: [
            {
                name: 'SET_PROFILE',
                key: 'profile',
                dataPath: 'data.user',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "updateProfile",
        path: baseUrl + "profile/update",
        method: "post",
        commits: [
            {
                name: 'SET_PROFILE',
                key: 'profile',
                dataPath: 'data.user',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "updatePassword",
        path: baseUrl + "profile/update-password",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "emailList",
        path: baseUrl + "emails/list",
        method: "get",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "changeStatusEmail",
        path: baseUrl + "emails/:id/update",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "deleteEmail",
        path: baseUrl + "emails/:id/delete",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "addEmail",
        path: baseUrl + "emails/insert",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "telegramAccount",
        path: baseUrl + "telegram-monitor-bot/list",
        method: "get",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "changeStatusTelegramAccount",
        path: baseUrl + "telegram-monitor-bot/:id/edit",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "DeleteTelegramAccount",
        path: baseUrl + "telegram-monitor-bot/:id/delete",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getValidSubscription",
        path: baseUrl + "subscription/get-user-valid-subscription",
        method: "get",
        commits: [
            {
                name: 'SET_SUBSCRIPTION',
                key: 'subscription',
                dataPath: 'data.subscription',
                localStoragePersist: false,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getUserSubscriptionHistory",
        path: baseUrl + "subscription/subscriptions-history",
        method: "get",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "submitFeedback",
        path: baseUrl + "feedback/insert",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "detachKeywordFromTelegramAccount",
        path: baseUrl + "telegram-monitor-bot/delete-keyword",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "addKeywordToTelegramAccount",
        path: baseUrl + "telegram-monitor-bot/add-keyword",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getNotification",
        path: baseUrl + "in-app-notification/list",
        method: "get",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },

    {
        name: "detachKeywordFromEmail",
        path: baseUrl + "emails/delete-keyword",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "addKeywordToEmail",
        path: baseUrl + "emails/add-keyword",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },

]
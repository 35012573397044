const baseUrl = 'user/'
/*eslint-disable*/
export default [
    {
        name: "getKeywords",
        path: baseUrl + "keywords/list",
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "insertKeyword",
        path: baseUrl + "keywords/insert",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "deleteKeyword",
        path: baseUrl + "keywords/:id/delete",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "editKeyword",
        path: baseUrl + "keywords/:id/update",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getKeyword",
        path: baseUrl + "keywords/:id/get",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getKeywordData",
        path: baseUrl + "keywords/:id/data?page=:page",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "getKeywordDataWithoutPaginate",
        path: baseUrl + "keywords/:id/data-without-paginate",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "importExcelFile",
        path: baseUrl + "keywords/import/excel",
        method: "post",
        commits: [],
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
    },

]
<template>
  <div>
    <v-dialog width="500px" v-model="openModalFilter">
      <FilterSection @applyFilter="applyFilters($event)" @resetFilter="resetFilter"/>
    </v-dialog>
    <div class="borderBottomBox  keywordsTable" v-if="keyword">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          کلمه کلیدی‌
          #{{ $route.params.id }}
        </div>
      </div>
      <div class="mt-10">
        <KeywordItem :keyword="keyword"/>
      </div>
      <div class="chartArea mb-10">
        <div class="heroTitle mt-10">
          آمار کلید واژه
          #{{ $route.params.id }}
        </div>
        <v-row>
          <v-col cols="12" lg="6">
            <div class="borderBottomBox  keywordsTable mt-10" style="min-height: 400px">
              <div class="heroTitle">
                تحلیل احساسات کاربران
              </div>
              <div class="mt-5" v-if="keyword">
                <SentimentChart :sentiment_chart="keyword.statistics.sentiment"/>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="borderBottomBox  keywordsTable mt-10" style="min-height: 400px">
              <div class="heroTitle">
                نمودار روند اتتشار محتوا
              </div>
              <div class="mt-5" v-if="keyword">
                <DataChart :data_chart="keyword.statistics.data"/>
              </div>
            </div>
          </v-col>
        </v-row>

      </div>


      <div class="heroTitle mt-10">
        دیتا‌های کلمه کلیدی
        #{{ $route.params.id }}
        <v-btn @click="openModalFilter = true" small color="primary" elevation="0" class="font-weight-bold mr-2">
          فیلتر
          <v-icon>
            mdi-filter
          </v-icon>
        </v-btn>
      </div>
      <div class="dataArea mt-5">
        <v-row v-if="data">
          <v-col cols="12" lg="3" v-for="(item , i ) in data" :key="i">
            <SimpleDataBox :id="item.id" :keyword="keyword.pattern" :data="item"/>
          </v-col>
        </v-row>
        <div class="pagination mt-10 px-10">
          <v-pagination v-model="page" @input="getKeywordData(true)" class="elevation-0" :length="total"></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {gsap} from "gsap";
import KeywordItem from "@/components/Keyword/KeywordItem";
import momentJalali from "moment-jalaali";
import SimpleDataBox from "@/components/Data/SimpleDataBox";
import moment from "moment";
import {EventBus} from "@/event-bus";
import FilterSection from "@/components/Data/FilterSection.vue";
import DataChart from "@/components/Chart/DataChart.vue";
import SentimentChart from "@/components/Chart/SentimentChart.vue";

export default {
  components: {SentimentChart, DataChart, FilterSection, SimpleDataBox, KeywordItem},
  mounted() {
    this.getKeyword()
    this.getKeywordData()
  },
  watch: {
    // page() {
    //   this.getKeywordData(true)
    // }
  },
  methods: {
    applyFilters(filters) {
      this.openModalFilter = false
      this.page = 1
      this.$nextTick().then(() => {
        this.getKeywordData(true, filters)
        if (filters.hasOwnProperty('from_date') && filters.from_date) {
          this.getKeyword(false, filters)
        }
      })
    },
    resetFilter() {
      this.openModalFilter = false
      this.getKeywordData(true, {})
    },
    getKeyword(need_to_load = true, filters = {}) {
      EventBus.$emit('setLoading', true)
      this.requesting('keyword', 'getKeyword', {id: this.$route.params.id}, filters).then((resp) => {
        this.keyword = null
        this.keyword = resp.data
        console.log(this.keyword, '12121')
        if (need_to_load) {
          this.loadKeyword()
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    getKeywordData(loader = false, filters = {}) {
      if (loader) {
        EventBus.$emit('setLoading', true)
      }
      let payload;
      if (Object.keys(filters).length === 0) {
        payload = {}
      } else {
        payload = filters
      }
      payload.page = this.page
      this.requesting('keyword', 'getKeywordData', {
        id: this.$route.params.id,
        page: this.page
      }, payload).then((resp) => {
        this.data = null
        this.$nextTick().then(() => {
          this.data = resp.data.data
          setTimeout(() => {
            EventBus.$emit('setLoading', false)
          }, 10)
        })
        this.total = resp.data.pagination.total_pages
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }, 100)
        // if (this.$route.query.id) {
        //   setTimeout(() => {
        //     document.getElementById(`${this.$route.query.id}_data_box`).scrollIntoView({behavior: 'smooth'});
        //     setTimeout(() => {
        //       window.scrollBy({
        //         top: -200, // Adjusting by -10 pixels to add space
        //         behavior: 'smooth' // Optional: keeps the scroll smooth
        //       });
        //     }, 1000);
        //   })
        // }
      }).finally(() => {
        if (loader) {
        }
      })
    },
    loadKeyword() {
      setTimeout(() => {
        // gsap.fromTo('.keywordsTable', {y: 100, opacity: 0,}, {
        //   y: 0,
        //   opacity: 1,
        // }).then(() => {
        // })
      }, 500)
    }
  },
  data() {
    return {
      data: [],
      page: 1,
      total: 1,
      openModalFilter: false,
      keyword: null,
    }
  },
  filters: {}
}
</script>

<style scoped>
a {
  text-decoration: none;

}


</style>
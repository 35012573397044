import axios from "axios";
import {EventBus} from "@/event-bus";

const baseUrl = process.env.VUE_APP_BASE_URL

/*eslint-disable*/
export function ajaxGet(path, payload, header, notif) {
    if (path) {
        return new Promise((resolve, reject) => {
            axios.get(baseUrl + path, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')} `,
                    // header
                },
            }).then((resp) => {
                return resolve(resp.data)
            }).catch((er) => {
                if (er.response.status == 401) {
                    if (!er.response.request.responseURL.includes('login')) {
                        // this.$router.push({name: "login"})
                        localStorage.removeItem('token')
                        window.location.replace('/login')
                    } else {
                        EventBus.$emit('notify', {
                            color: 'red',
                            text: 'رمز عبور یا نام کاربری اشتباه است ',
                            type: 'error'
                        })
                    }

                }
                return reject(er)
            })
        })
    }
}

export function ajaxPost(path, payload, header, notif) {
    if (path) {

        return new Promise((resolve, reject) => {
            axios.post(baseUrl + path, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')} `,
                    // header
                },
            }).then((resp) => {
                return resolve(resp.data)
            }).catch((er) => {
                if (er.response.status == 401) {
                    if (!er.response.request.responseURL.includes('login')) {
                        this.$router.push({name: "login"})
                        localStorage.removeItem('token')
                    } else {
                        EventBus.$emit('notify', {
                            color: 'red',
                            text: 'رمز عبور یا نام کاربری اشتباه است ',
                            type: 'error'
                        })
                    }

                    // window.location.replace('/login')
                }
                if (er.response.status == 400) {
                    if (er.response.data.data && Object.keys(er.response.data.data).length != 0) {
                        console.log('in it')
                        let key = Object.keys(er.response.data.data)
                        let er_text = er.response.data.data[key[0]]
                        if (er_text && er_text.length != 0) {
                            EventBus.$emit('notify', {text: er_text[0], color: 'red', type: 'error'})
                        }
                    } else {
                        console.log('here',er.response.data.message)
                        EventBus.$emit('notify', {text: er.response.data.message, color: 'red', type: 'error'})

                    }
                }
                return reject(er)
            })
        })
    }
}

let httpUtilities = {
    get: ajaxGet,
    post: ajaxPost
}
export default httpUtilities
const baseUrl = 'user/'
/*eslint-disable*/
export default [
    {
        name: "getReports",
        path: baseUrl + "reports/list",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
    {
        name: "generateReport",
        path: baseUrl + "reports/:type/generate",
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {},
    },
]
<template>
  <div>
    <v-dialog v-model="loader" width="300px" persistent>
      <v-card>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-fade-transition mode="out-in">
      <router-view/>
    </v-fade-transition>
    <v-snackbar v-model="notify.visible" :color="notify.color" :timeout="notify.timeOut">
      <template v-if="notify.type == 'wellcome'">
        <v-icon class="ml-1">mdi-drama-masks</v-icon>
      </template
      >
      <template v-if="notify.type == 'error'">
        <v-icon class="ml-1">mdi-alert</v-icon>
      </template>
      {{ notify.text }}
    </v-snackbar>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      loader: false,
      notify: {
        visible: false,
        color: 'second',
        text: null,
        type: null,
        timeOut: 5000,
      }
    }
  },
  mounted() {
    EventBus.$on('setLoading', (val) => {
      this.loader = false
      this.$nextTick().then(() => {
        this.loader = val
      }).then(() => {
        setTimeout(() => {
          if (this.loader) {
            this.loader = false
          }
        }, 30000)
      })
    })

    EventBus.$on('notify', ({color, text, type}) => {
      this.notify.visible = false
      this.$nextTick().then(() => {
        this.notify.visible = true
        this.notify.color = color
        this.notify.text = text
        this.notify.type = type
      })
    })
  }
}
</script>

<style scoped>

</style>
/*eslint-disable*/
import requestList from "../request/requestList";
import httpUtilities from "./httpUtilities";
import CommitResolver from "./CommitResolver";

class APIResolver {
    getApi(type, name) {
        console.log()
        return requestList[type].find(item => item.name === name)
    }

    sendRequest(type = null, name = null, paramsQuery = null, body = null, header = null) {
        return new Promise((resolve, reject) => {
            let request = this.getApi(type, name)
            if (request) {
                let url;
                if (paramsQuery) {
                    console.log(request)
                     url = this.prepareRequestPath(request.path, paramsQuery)
                }
                if (Object.keys(request.defaultHeader).length != 0 && request.defaultHeader) {
                    header = this.prepareDefaultHeader(header, request.defaultHeader)
                }
                if (Object.keys(request.defaultBody).length != 0 && request.sendRequest) {
                    body = this.prepareDefaultBody(body, request.defaultBody)
                }
                httpUtilities[request.method](url, body, header).then((resp) => {
                    if (request.commits) {
                        new CommitResolver(request.commits,resp.data).committingData()
                    }
                    resolve(resp)
                }).catch((er) => {
                    console.log(er.response)
                    reject(er)
                })
            }
        })
    }

    prepareRequestPath(path, paramsQuery) {
        let temp = path;
        Object.keys(paramsQuery).forEach(key => {
            temp = temp.replace(`:${key}`, paramsQuery[key])
        })
        return temp;
    }

    prepareDefaultHeader(header, defaultHeader) {
        Object.keys(defaultHeader).forEach(key => {
            if (defaultHeader[key]) {
                Object.assign(header, {[key]: defaultHeader[key]})
            }
        })
        return header
    }

    prepareDefaultBody(body, defaultBody) {
        Object.keys(defaultBody).forEach(key => {
            if (defaultBody[key]) {
                Object.assign(body, {[key]: defaultBody[key]})
            }
        })
        return body
    }
}

export default APIResolver
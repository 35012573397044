/*eslint-disable*/
import store from '../src/store/index'
import LS from "./LocalStorageAPI";

class CommitResolver {
    constructor(commits, resp) {
        this.commits = commits
        this.resp = resp
    }

    committingData() {
        this.commits.forEach(item => {
            if (item.before) item.before(this.resp, store.state)
            if (item.debug) console.log('before: ' + store.state[item.key])
            console.log('doing!!!')
            store.commit(item.name, {
                key: item.key,
                ttl: 3000,
                data: this.prepareDateToCommit(item, this.resp)
            })
            if (item.localStoragePersist) {
                LS.set(item.key, this.prepareDateToCommit(item, this.resp))
            }
            if (item.debug) console.log('after: ' + store.state[item.key])
            if (item.after) item.after(this.resp, store.state)


        })
        console.log(store.state.primaryPicture)
    }

    prepareDateToCommit(commit, resp) {
        // return this.getDipDataResponse(resp, commit.dataPath)

        if (commit.hasOwnProperty('data')) {
            return commit.data
        } else if (commit.hasOwnProperty('dataPath')) {
            return this.getDipDataResponse(resp, commit.dataPath)
        } else if (commit.hasOwnProperty('dataClosure')) {
            return commit.dataClosure(resp, store)
        }
    }

    getDipDataResponse(data, data_path) {
        let keys = data_path.split('.')
        if (data.hasOwnProperty(keys[0])) {
            data = data[keys[0]]
        }
        return (keys.length == 1) ? data : keys.shift() && this.getDipDataResponse(data, keys.join('.'));
    }
}

export default CommitResolver
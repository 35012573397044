<template>
  <div>
    <v-dialog width="500px" v-model="config.visibility">
      <v-card>
        <v-card-title class="red white--text">
          اخطار
        </v-card-title>
        <v-card-text class="mt-4">
          <div class="textMessage">
            با پاک کردن کلمه کلیدی، دیتاهای این کلمه کلیدی هم پاک می‌شود و شما دیتادیگری روی این کلمه کلیدی دریافت
            نمیکنید. از این کار مطمئن هستید؟
          </div>
          <div class="d-flex mt-5">
            <v-btn color="red white--text" class="font-weight-bold" elevation="0">
              پاک کردن
            </v-btn>
            <v-btn class="mr-4 font-weight-bold" elevation="0" color="secondary" text>
              لغو
            </v-btn>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.textMessage {
  font-size: 15px;
  line-height: 20pt;
}
</style>
<template>
  <div>
    <div class="borderBottomBox">
      <div class="d-flex justify-space-between">
        <div class="heroTitle">
          اطلاعات حساب کاربری
        </div>
      </div>
      <v-fade-transition>
        <div class="profileArea mt-10" v-if="user">
          <v-form ref="profile">
            <v-row>
              <v-col cols="12" lg="3">
                <v-text-field :rules="rules.required" v-model="user.first_name" label="نام " outlined
                              dense></v-text-field>
              </v-col>
              <v-col cols="12" lg="3">
                <v-text-field :rules="rules.required" v-model="user.last_name" label="نام خانوادگی " outlined
                              dense></v-text-field>
              </v-col>
              <v-col cols="12" lg="3">
                <div class="ltrForm">
                  <v-text-field readonly disabled label="ایمیل " v-model="user.email" outlined dense></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" lg="3">
                <div class="ltrForm">
                  <v-text-field label="شماره تماس " :rules="rules.phoneRules" v-model="phone" outlined
                                dense></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="text-left">
            <v-btn @click="updatedProfile" color="primary" elevation="0" class="font-weight-bold">
              ویرایش
            </v-btn>
          </div>
        </div>

      </v-fade-transition>
      <div class="d-flex justify-space-between mt-10">
        <div class="heroTitle">
          ویرایش رمز عبور
        </div>
      </div>
      <div class="resetPassword mt-10">
        <v-form ref="reset_password">
          <v-row>
            <v-col cols="12" lg="3">
              <v-text-field v-model="old_password" :rules="rules.required" label="رمز قبلی " outlined
                            dense></v-text-field>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field v-model="new_password" :rules="rules.required" label="رمز جدید " outlined
                            dense></v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="text-left">
                <v-btn color="primary" @click="resetPassword" elevation="0" class="font-weight-bold">
                  ثبت رمز عبور جدید
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      user: null,
      old_password: null,
      new_password: null,
    }
  },
  methods: {
    getProfile() {
      EventBus.$emit('setLoading', true)
      this.requesting('user', 'getProfile').then((resp) => {
        this.user = resp.data.user
        this.phone = this.user.phone
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    updatedProfile() {
      if (this.$refs.profile.validate()) {
        EventBus.$emit('setLoading', true)
        let payload = this.user
        payload.tell = this.phone
        this.requesting('user', 'updateProfile', {}, payload).then((resp) => {
          this.user = resp.data.user
          EventBus.$emit('notify', {text: 'اطلاعات کاربری شما به روز شد', color: 'green'})
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
    resetPassword() {
      if (this.$refs.reset_password.validate()) {
        EventBus.$emit('setLoading', true)
        this.requesting('user', 'updatePassword', {}, {
          new_password: this.new_password,
          old_password: this.old_password
        }).then(() => {
          EventBus.$emit('notify', {text: 'ویرایش رم عبور با موفقیت انحام شد', color: 'green'})
          this.$refs.reset_password.reset()
        }).catch((er) => {
          EventBus.$emit('setLoading', false)
          if (er.response.status == 400) {
            EventBus.$emit('notify', {text: er.response.data.message, color: 'red'})
          }
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.getProfile()
    })
  }
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./DashboardDataChart.vue?vue&type=template&id=6d5a8c58&scoped=true&"
import script from "./DashboardDataChart.vue?vue&type=script&lang=js&"
export * from "./DashboardDataChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d5a8c58",
  null
  
)

export default component.exports
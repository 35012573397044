<template>
  <div>
    <v-container>
      <v-dialog width="550px" v-model="addModal" transition="dialog-bottom-transition">
        <v-card>
          <v-card-title style="font-family: bold !important;">
            افزودن تیکت
          </v-card-title>
          <v-card-text class="mt-4">
            <v-form ref="ticket">
              <v-text-field :rules="rules.required" v-model="ticket.title" label="تیتر تیکت" outlined
                            dense></v-text-field>
              <v-textarea :rules="rules.required" v-model="ticket.message" outlined label="متن پیام"></v-textarea>
<!--              <v-file-input v-model="ticket.file" label="فایل" outlined dense persistent-hint-->
<!--                            hint="این فایل ها به صورت امن نگه داری می‌شوند"></v-file-input>-->
            </v-form>
            <v-btn @click="makeTicket" color="primary" elevation="0" class="mt-3">
              ارسال
              <v-icon style="transform: rotate(180deg)" class="mr-2">mdi-send</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="borderBottomBox hiddenForAnimation keywordsTable">
        <div class="d-flex justify-space-between">
          <div class="heroTitle">
            تیکت‌های پشتیبانی
          </div>
          <div>
            <div>
              <v-btn color="secondary" elevation="0" class="font-weight-bold" style="border-radius: 10px"
                     @click="addModal = true">
                <v-icon class="ml-1">mdi-pencil</v-icon>
                افزودن تیکت
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <v-data-table v-if="data" :items="data" :headers="headers" disable-pagination hide-default-footer>
            <template class="" v-slot:item.action="{item}">
              <v-btn  :to="`/dashboard/support/${item.id}`"  small elevation="0"
                     class=" ma-3 tableBtn">
                 مشاهده
                <v-icon small class="mr-1">mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.last_update_at="{item}">
              {{ item.last_update_at | dateFilterV2 }}
            </template>
            <template v-slot:item.state="{item}">
              <template v-if="item.state == 'wait_for_user'">
                <div class="answerTickets">
                  پاسخ داده‌شده
                </div>
              </template>
              <template v-else-if="item.state == 'wait_for_admin'">
                <div class="NotAnswerTickets">
                  در انتظار پاسخ
                </div>
              </template>
              <template v-else-if="item.state == 'close'">
                <div class="closeTickets">
                  بسته‌شده
                </div>
              </template>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import {gsap} from "gsap";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      addModal: false,
      data: null,
      headers: [
        {
          text: 'کد تیکت',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'عنوان تیکت',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {
          text: 'وضعیت',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'آخرین پیام',
          align: 'start',
          sortable: false,
          value: 'last_update_at',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
      ticket: {
        title: '',
        message: '',
        file: '',
      }
    }
  },
  methods: {
    getTickets(neet_to_load = true) {
      EventBus.$emit('setLoading', true)
      this.requesting('ticket', 'getTickets').then((resp) => {

        if (neet_to_load) {
          this.load()
        }
        this.data = resp.data.tickets

      }).finally(() => {

        EventBus.$emit('setLoading', false)
      })
    },
    load() {
      setTimeout(() => {
        gsap.fromTo('.keywordsTable', {y: 100, opacity: 0}, {y: 0, opacity: 1})
      }, 100)
    },
    makeTicket() {
      if (this.$refs.ticket.validate()) {
        EventBus.$emit('setLoading', true)
        let data = new FormData();
        data.append('title', this.ticket.title)
        data.append('message', this.ticket.message)
        if (this.ticket.file){
          data.append('file', this.ticket.file)
        }
        this.requesting('ticket', 'makeTicket', {}, data).then(() => {
          EventBus.$emit('notify', {text: 'تیکت شما ارسال شد و در انتظار پاسخ است.', color: 'green'})
          this.getTickets(false)
          this.addModal = false
          this.$refs.ticket.reset()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }

    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.getTickets()
    })
  }
}
</script>

<style scoped>
.answerTickets {
  color: mediumseagreen;
  font-family: bold !important;
  background: rgba(60, 179, 113, 0.1);
  width: 140px;
  font-size: 11px;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}

.NotAnswerTickets {
  color: #ff8601;
  font-family: bold !important;
  background: rgba(255, 134, 1, .2);
  width: 140px;
  font-size: 11px;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}

.closeTickets {
  color: red;
  font-family: bold !important;
  background: rgba(255, 0, 0, .2);
  width: 140px;
  font-size: 11px;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}
</style>
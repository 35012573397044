<template>
  <v-app>
    <v-main class="pa-0">
      <router-view class="fill-height pa-0 ma-0"/>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import globalMixins from "./mixins/globalMixins";

import datePicker from '@alireza-ab/vue-persian-datepicker'

Vue.component('date-picker', datePicker)
Vue.mixin(globalMixins)
export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {
    if (localStorage.getItem('token') == null) {
      // this.$router.push({name: "login"})
    }
  }
};
</script>
<style>
@import url('styles/main.style.css');

.fill-height {
  height: 100%;
}
</style>